import * as React from 'react';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { DistributiveOmit } from '@mui/types';

interface TypedFormControlLabelProps<T extends string> {
    value: T;
    values?: [T, T];
    currentValue?: T;
    marginLeft?: number;
    handleChange: (val: T) => void;
}

export default function <T extends string>(props: DistributiveOmit<FormControlLabelProps, 'control'> & TypedFormControlLabelProps<T>) {
    const { value, currentValue, handleChange, marginLeft, values, ...others } = props;
    const checked = values ? values.some(val => val === props.currentValue) : props.value === props.currentValue;
    return (
        <FormControlLabel
            {...others}
            checked={checked}
            value={props.value}
            style={{ marginLeft: marginLeft || -6 }}
            control={<Radio color="secondary" />}
            onChange={() => props.handleChange(props.value)}
        />
    );
}

export function RadioLabel({ title, text }: { title: string, text: string }) {
    return (
        <>
            <Typography>{title}</Typography>
            <Typography variant="caption">{text}</Typography>
        </>
    );
}

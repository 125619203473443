import * as React from 'react';
import ProBadge from './ProBadge';
import { Flex } from './Misc';
import { AppColors } from 'src/main/Theme';

export const LogoSvg = (white: boolean) => (
    <svg width="164" height="20" viewBox="0 0 164 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.2444 9.36768C16.2444 10.6302 16.0471 11.7899 15.6525 12.8469C15.2725 13.9039 14.7245 14.814 14.0084 15.5774C13.3068 16.3408 12.4592 16.9353 11.4654 17.361C10.4716 17.7867 9.37544 17.9996 8.17702 17.9996C6.99322 17.9996 5.90441 17.8014 4.9106 17.4051C3.91679 16.994 3.05451 16.4362 2.32377 15.7315C1.59302 15.0122 1.02304 14.1608 0.613825 13.1772C0.204608 12.1789 0 11.0926 0 9.91819C0 8.74378 0.197301 7.66479 0.591902 6.68122C1.00112 5.68297 1.56379 4.82418 2.27992 4.10485C3.01066 3.37084 3.87294 2.80566 4.86675 2.40929C5.86056 1.99825 6.94937 1.79273 8.13318 1.79273C9.1416 1.79273 10.0916 1.94687 10.9831 2.25515C11.8746 2.54876 12.6711 2.96714 13.3726 3.5103C14.0741 4.05347 14.6587 4.6994 15.1264 5.44808C15.6087 6.19677 15.9302 7.01886 16.091 7.91435H11.6627C11.385 7.25374 10.9319 6.7326 10.3035 6.35091C9.67505 5.96923 8.96622 5.77839 8.17702 5.77839C7.60704 5.77839 7.08091 5.88115 6.59862 6.08667C6.13094 6.27751 5.72172 6.55644 5.37097 6.92344C5.03482 7.29044 4.77176 7.73085 4.58176 8.24465C4.40638 8.74378 4.3187 9.30162 4.3187 9.91819C4.3187 10.5201 4.40638 11.0779 4.58176 11.5917C4.77176 12.0909 5.03482 12.5239 5.37097 12.8909C5.70711 13.2432 6.10902 13.5222 6.57669 13.7277C7.05898 13.9185 7.59243 14.014 8.17702 14.014C8.98084 14.014 9.69697 13.8378 10.3254 13.4855C10.9538 13.1185 11.3923 12.634 11.6407 12.0321H7.82626V9.36768H16.2444Z"
            fill={white ? AppColors.white : AppColors.webBlue400} />
        <path d="M24.2403 17.9996C23.2757 17.9996 22.3842 17.8455 21.5657 17.5372C20.7619 17.2142 20.0604 16.7738 19.4612 16.216C18.8766 15.6434 18.4162 14.9755 18.0801 14.2121C17.7586 13.4341 17.5978 12.5826 17.5978 11.6578C17.5978 10.7329 17.7586 9.88883 18.0801 9.12546C18.4162 8.34741 18.8766 7.67947 19.4612 7.12162C20.0604 6.5491 20.7619 6.10869 21.5657 5.80041C22.3842 5.47744 23.2757 5.31596 24.2403 5.31596C25.2048 5.31596 26.089 5.47744 26.8929 5.80041C27.7113 6.10869 28.4128 6.5491 28.9974 7.12162C29.5966 7.67947 30.057 8.34741 30.3785 9.12546C30.7146 9.88883 30.8827 10.7329 30.8827 11.6578C30.8827 12.5826 30.7146 13.4341 30.3785 14.2121C30.057 14.9755 29.5966 15.6434 28.9974 16.216C28.4128 16.7738 27.7113 17.2142 26.8929 17.5372C26.089 17.8455 25.2048 17.9996 24.2403 17.9996ZM24.2403 14.3222C24.9856 14.3222 25.5921 14.08 26.0598 13.5956C26.5421 13.0964 26.7832 12.4505 26.7832 11.6578C26.7832 10.8651 26.5421 10.2265 26.0598 9.74203C25.5921 9.2429 24.9856 8.99334 24.2403 8.99334C23.4949 8.99334 22.8811 9.2429 22.3988 9.74203C21.9165 10.2265 21.6753 10.8651 21.6753 11.6578C21.6753 12.4505 21.9165 13.0964 22.3988 13.5956C22.8811 14.08 23.4949 14.3222 24.2403 14.3222Z"
            fill={white ? AppColors.white : AppColors.webBlue400} />
        <path d="M32.8811 1.41838H36.9586V17.6032H32.8811V1.41838Z"
            fill={white ? AppColors.white : AppColors.webBlue400} />
        <path d="M47.5413 9.12546H44.8667V17.6032H40.7892V9.12546H38.8381V5.91051H40.855V4.76546C40.855 3.57637 41.1692 2.65152 41.7976 1.99091C42.4407 1.3303 43.3979 1 44.6694 1H47.5193V4.12687H45.6998C45.4075 4.12687 45.1737 4.21495 44.9983 4.39111C44.8229 4.56728 44.7352 4.80216 44.7352 5.09576V5.91051H47.5413V9.12546Z"
            fill={white ? AppColors.white : AppColors.webBlue400} />
        <path d="M55.7607 2.34052C56.5738 2.34052 57.3216 2.47865 58.004 2.75492C58.6865 3.03118 59.2818 3.4165 59.79 3.91088C60.2982 4.39071 60.6902 4.97233 60.9661 5.65572C61.2565 6.32458 61.4017 7.05887 61.4017 7.85859C61.4017 8.65831 61.2565 9.39987 60.9661 10.0833C60.6902 10.7521 60.2982 11.3337 59.79 11.8281C59.2818 12.3225 58.6865 12.7078 58.004 12.9841C57.3216 13.2603 56.5738 13.3985 55.7607 13.3985H53.4738V17.6079H49.2703V2.34052H55.7607ZM55.2598 9.71249C55.8115 9.71249 56.2544 9.54527 56.5883 9.21085C56.9223 8.86188 57.0893 8.41112 57.0893 7.85859C57.0893 7.3206 56.9223 6.87711 56.5883 6.52815C56.2544 6.17918 55.8115 6.00469 55.2598 6.00469H53.4738V9.71249H55.2598Z"
            fill={white ? AppColors.white : AppColors.webBlue400} />
        <path d="M75.659 5.8084V17.5861H72.5444L72.1959 16.7573C71.7023 17.1499 71.1578 17.4552 70.5624 17.6733C69.9671 17.8914 69.3282 18.0005 68.6458 18.0005C67.7456 18.0005 66.9179 17.8478 66.1629 17.5425C65.4078 17.2226 64.7544 16.7864 64.2027 16.2338C63.6509 15.6668 63.2226 14.9979 62.9177 14.2273C62.6127 13.4566 62.4603 12.6133 62.4603 11.6972C62.4603 10.7812 62.6127 9.94513 62.9177 9.18903C63.2226 8.41839 63.6509 7.75681 64.2027 7.20427C64.7544 6.65174 65.4078 6.2228 66.1629 5.91745C66.9179 5.6121 67.7456 5.45943 68.6458 5.45943C69.3428 5.45943 69.9889 5.56848 70.5842 5.78659C71.1941 6.00469 71.7458 6.31731 72.2395 6.72444L72.6533 5.8084H75.659ZM69.1903 14.4018C69.5678 14.4018 69.9163 14.3363 70.2357 14.2055C70.5552 14.0601 70.8311 13.871 71.0634 13.6384C71.2957 13.3912 71.4772 13.1077 71.6079 12.7878C71.7386 12.4533 71.8039 12.0971 71.8039 11.7191C71.8039 11.341 71.7386 10.992 71.6079 10.6722C71.4772 10.3377 71.2957 10.0542 71.0634 9.82154C70.8311 9.57435 70.5552 9.38533 70.2357 9.25447C69.9163 9.10906 69.5678 9.03636 69.1903 9.03636C68.4062 9.03636 67.7673 9.29082 67.2737 9.79973C66.7945 10.3086 66.5549 10.9484 66.5549 11.7191C66.5549 12.0971 66.6203 12.4533 66.7509 12.7878C66.8816 13.1077 67.0631 13.3912 67.2954 13.6384C67.5278 13.871 67.8036 14.0601 68.1231 14.2055C68.457 14.3363 68.8128 14.4018 69.1903 14.4018Z"
            fill={white ? AppColors.white : AppColors.webBlue400} />
        <path d="M90.7608 1.57715V17.6079H87.7552L87.3849 16.7355C86.9058 17.1426 86.3685 17.4552 85.7732 17.6733C85.1779 17.8914 84.5318 18.0005 83.8348 18.0005C82.9346 18.0005 82.0997 17.8478 81.3301 17.5425C80.5751 17.2226 79.9216 16.7864 79.3699 16.2338C78.8327 15.6813 78.4116 15.0197 78.1066 14.2491C77.8017 13.4784 77.6493 12.6351 77.6493 11.7191C77.6493 10.803 77.8017 9.95967 78.1066 9.18903C78.4116 8.41839 78.8327 7.75681 79.3699 7.20427C79.9216 6.65174 80.5751 6.2228 81.3301 5.91745C82.0997 5.59756 82.9346 5.43762 83.8348 5.43762C84.3866 5.43762 84.902 5.51032 85.3812 5.65572C85.8749 5.80113 86.3322 6.00469 86.7533 6.26642V1.57715H90.7608ZM84.3793 14.4236C84.7568 14.4236 85.1053 14.3581 85.4247 14.2273C85.7442 14.0819 86.0201 13.8928 86.2524 13.6602C86.4992 13.413 86.688 13.1295 86.8187 12.8096C86.9493 12.4752 87.0147 12.1116 87.0147 11.7191C87.0147 11.3265 86.9493 10.9702 86.8187 10.6503C86.688 10.3159 86.4992 10.0324 86.2524 9.79973C86.0201 9.55254 85.7442 9.36352 85.4247 9.23266C85.1053 9.08725 84.7568 9.01455 84.3793 9.01455C83.6097 9.01455 82.9781 9.26901 82.4844 9.77792C81.9907 10.2868 81.7439 10.9339 81.7439 11.7191C81.7439 12.5042 81.9907 13.1513 82.4844 13.6602C82.9781 14.1691 83.6097 14.4236 84.3793 14.4236Z"
            fill={white ? AppColors.white : AppColors.webBlue400} />
        <path d="M95.8357 15.6197H102.811V17.4552H93.9294V2.15234H102.577V3.97716H95.8357V8.70461H102.002V10.4761H95.8357V15.6197Z"
            fill={white ? AppColors.white : AppColors.webBlack} />
        <path d="M113.847 5.65258H115.743L110.78 17.4552H109.097L103.996 5.65258H105.934L109.928 15.0434L113.847 5.65258Z"
            fill={white ? AppColors.white : AppColors.webBlack} />
        <path d="M128.77 11.6072C128.77 11.8207 128.759 12.0412 128.738 12.2689H118.695C118.837 13.3645 119.284 14.2431 120.037 14.9047C120.797 15.5663 121.73 15.8972 122.838 15.8972C123.647 15.8972 124.368 15.7193 125 15.3636C125.631 15.0008 126.132 14.4992 126.501 13.8589H128.461C127.957 15.0755 127.211 16.0217 126.224 16.6975C125.245 17.3734 124.116 17.7113 122.838 17.7113C121.716 17.7113 120.694 17.4445 119.771 16.911C118.855 16.3774 118.134 15.641 117.609 14.702C117.09 13.7629 116.831 12.7135 116.831 11.5539C116.831 10.4014 117.09 9.35557 117.609 8.41648C118.134 7.47028 118.855 6.73039 119.771 6.19682C120.694 5.66325 121.716 5.39646 122.838 5.39646C124.002 5.39646 125.039 5.67392 125.947 6.22884C126.856 6.78375 127.552 7.53431 128.035 8.48051C128.525 9.4196 128.77 10.4618 128.77 11.6072ZM122.838 7.15725C121.773 7.15725 120.871 7.46672 120.133 8.08567C119.394 8.6975 118.929 9.5192 118.738 10.5508H126.917C126.718 9.50497 126.249 8.67971 125.511 8.075C124.78 7.46317 123.888 7.15725 122.838 7.15725Z"
            fill={white ? AppColors.white : AppColors.webBlack} />
        <path d="M137.547 5.39646C138.925 5.39646 140.025 5.86245 140.849 6.79442C141.68 7.72639 142.095 8.92515 142.095 10.3907V17.4552H140.242V10.8282C140.242 9.63303 139.968 8.71172 139.422 8.06432C138.875 7.41692 138.091 7.09322 137.068 7.09322C136.06 7.09322 135.215 7.47028 134.534 8.2244C133.852 8.97851 133.511 9.91048 133.511 11.0203V17.4552H131.648V5.65258H132.968L133.33 7.49874C133.777 6.83711 134.367 6.32132 135.098 5.95138C135.829 5.58143 136.646 5.39646 137.547 5.39646Z"
            fill={white ? AppColors.white : AppColors.webBlack} />
        <path d="M150.532 15.7371H151.81V17.4552H150.287C149.016 17.4552 148.015 17.0888 147.284 16.356C146.56 15.6161 146.198 14.6059 146.198 13.3253V7.32799H144.121V6.99718L147.763 3.15546H148.051V5.65258H151.746V7.32799H148.051V13.272C148.051 14.0688 148.264 14.6806 148.69 15.1075C149.116 15.5272 149.73 15.7371 150.532 15.7371Z"
            fill={white ? AppColors.white : AppColors.webBlack} />
        <path d="M158.128 17.7113C156.736 17.7113 155.618 17.3627 154.773 16.6655C153.928 15.9683 153.481 15.0257 153.431 13.8376H155.242C155.291 14.5205 155.582 15.0506 156.115 15.4276C156.655 15.8047 157.347 15.9932 158.192 15.9932C159.008 15.9932 159.672 15.8296 160.183 15.5023C160.701 15.1751 160.961 14.7269 160.961 14.1577C160.961 13.9372 160.925 13.738 160.854 13.5601C160.783 13.3823 160.669 13.2329 160.513 13.1119C160.357 12.9839 160.201 12.8771 160.045 12.7918C159.888 12.6993 159.672 12.6175 159.395 12.5463C159.125 12.4752 158.891 12.4218 158.692 12.3863C158.5 12.3436 158.238 12.2938 157.904 12.2369C157.641 12.1942 157.435 12.1586 157.286 12.1301C157.144 12.1017 156.946 12.059 156.69 12.0021C156.442 11.9452 156.243 11.8918 156.094 11.842C155.945 11.7922 155.76 11.7282 155.54 11.6499C155.32 11.5717 155.142 11.4863 155.007 11.3938C154.88 11.3013 154.734 11.1946 154.571 11.0737C154.407 10.9456 154.276 10.8069 154.177 10.6575C154.084 10.5081 153.992 10.3445 153.9 10.1666C153.808 9.98163 153.74 9.77531 153.697 9.54765C153.662 9.32 153.644 9.07455 153.644 8.81132C153.644 7.79398 154.052 6.97228 154.869 6.34622C155.685 5.71305 156.757 5.39646 158.085 5.39646C159.413 5.39646 160.474 5.72728 161.269 6.38891C162.072 7.04342 162.491 7.92915 162.526 9.0461H160.769C160.733 8.40581 160.478 7.91492 160.002 7.57344C159.526 7.22484 158.873 7.05054 158.043 7.05054C157.247 7.05054 156.615 7.20705 156.147 7.52008C155.678 7.82599 155.444 8.23507 155.444 8.7473C155.444 8.9465 155.476 9.12791 155.54 9.29154C155.604 9.44805 155.71 9.58323 155.859 9.69705C156.008 9.81088 156.158 9.90693 156.307 9.98518C156.456 10.0634 156.662 10.1346 156.924 10.1986C157.187 10.2626 157.411 10.3124 157.595 10.348C157.78 10.3836 158.039 10.4263 158.373 10.4761C159.537 10.6682 160.371 10.8709 160.875 11.0843C162.04 11.5752 162.672 12.4361 162.771 13.6668C162.785 13.802 162.792 13.9372 162.792 14.0723C162.792 15.1751 162.366 16.0572 161.514 16.7189C160.662 17.3805 159.533 17.7113 158.128 17.7113Z"
            fill={white ? AppColors.white : AppColors.webBlack} />
    </svg>
);

type AppLogoProps = {
    hasPro?: boolean;
    floatFight?: boolean;
    inv?: boolean;
    fontSize?: number | string;
    onclk?: () => void;
};

const GolfpadEventsLogo = (props: AppLogoProps) => {
    const { fontSize, onclk, hasPro, floatFight, inv } = props;
    return (
        <span style={{ fontWeight: 300, fontFamily: 'roboto', fontSize, float: floatFight ? 'right' : undefined }}>
            <Flex>
                <span style={{ cursor: onclk ? 'pointer' : '', paddingRight: '4px', height: '20px' }} onClick={onclk}>
                    {LogoSvg(!inv)}
                </span>
                {hasPro && <ProBadge />}
            </Flex>
        </span>
    );
}

export default GolfpadEventsLogo;

//
// Shared EVENTS types, interfaces and functions!!!
//

import {
    Par, Tee, HandicapSystem, HolesType, Gender, getHolesRange, getAppMainCompetition,
    Contact, Team, Competition, getTee, getGolferMainCompetition, ScoringFormatTeams, ContactInfo,
    MAX_HOLES, NINE_HOLES, HOLES_FRONT_9, HOLES_BACK_9, HOLES_9, HOLES_9_9, ScoringData, isTeamFormat, getTeamOfContact, EventBase
} from '../types/EventTypes';

import * as Utils from '../util/utility';
import { golfersOfTeam } from '../contact/Contact';

const fractionDigitsDefault = 4;

export function parseHandicap(value?: string | number) {
    if (!value || value === '0') {
        return 0;
    }
    let res: number | undefined;
    if (typeof value === 'string') {
        if (value.length === 0) { // allow empty handicap
            return 0;
        }
        res = parseFloat(value.replace(',', '.'));
        if (value.startsWith('+')) {
            res *= -1;
        }
    }
    if (typeof value === 'number') {
        res = value;
    }
    if (res && isValidHCPIndex(res)) {
        return Math.round(res * 10) / 10;
    }
    return;
}

export function isValidHCPAllowance(allowance?: number) {
    return allowance != null && allowance >= 1 && allowance <= 100;
}

export function isValidHCPIndex(index?: number) {
    return index != null && index > -100 && index < 100;
}

export function isValidOverride(override?: number) {
    return override != null && override > -100 && override < 100;
}

export function correctRating(rating: number) {
    return Utils.round(rating, 1);
}

export function getFrontRating(tee: Tee) {
    if (tee.par.length === 9) {
        return tee.rating;
    }
    if (tee.ratingFront) {
        return tee.ratingFront;
    }
    return tee.rating ? Utils.round(tee.rating / 2, 1) : 0;
}

export function getBackRating(tee: Tee) {
    if (tee.ratingBack) {
        return tee.ratingBack;
    }
    return tee.rating ? Utils.round(tee.rating - getFrontRating(tee), 1) : 0;
}

export function getSlope(tee: Tee) {
    if (tee.slope) {
        return tee.slope;
    }
    return (tee.slopeFront && tee.slopeBack) ? Utils.round((tee.slopeFront + tee.slopeBack) / 2, 0) : 0;
}

export function getFrontSlope(tee: Tee): number {
    if (tee.slope) {
        return tee.slope;
    }
    return tee.slopeFront ? tee.slopeFront : 0;
}

export function getBackSlope(tee: Tee): number {
    if (tee.slope) {
        return tee.slope;
    }
    return tee.slopeBack ? tee.slopeBack : 0;
}

export function getRating(tee: Tee, holesType?: HolesType): number {
    if (holesType === HOLES_FRONT_9) {
        return tee.ratingFront ? tee.ratingFront : tee.rating ? Utils.round(tee.rating / 2, 1) : 0;
    }
    if (holesType === HOLES_BACK_9) {
        return tee.ratingBack ? tee.ratingBack : tee.rating ? Utils.round(tee.rating / 2, 1) : 0;
    }
    if (tee.rating) {
        return holesType === HOLES_9_9 ? 2 * tee.rating : tee.rating;
    } else {
        return (tee.ratingFront && tee.ratingBack) ? Utils.round(tee.ratingFront + tee.ratingBack, 1) : 0;
    }
}

export function getTotalPar(holesType?: HolesType, tee?: Tee): number {
    if (!tee || !tee.par || !tee.par.length) {
        return 0;
    }
    let totalPar = 0;
    const holesRange = getHolesRange(holesType);
    for (let hole = holesRange.first; hole < holesRange.last; hole++) {
        totalPar += tee.par[hole % tee.par.length];
    }
    return totalPar;
}

// reference implementation: 
// com.contorra.golfpad.scoring.PlayerCursor.getCourseHandicap(CourseTeeSet tee, Contact contact, boolean ignoreCourseHandicapOverride)
function getBasicCourseHandicap(holesType?: HolesType, tee?: Tee | null, handicapSystem?: HandicapSystem, playerHandicap?: number, handicapAllowance?: number): number {
    if (!tee || playerHandicap == null) {
        return 0;
    }
    if (!handicapSystem) {
        handicapSystem = tee.handicapSystem;
    }
    // CONGU, SMPLFD
    if (handicapSystem === 'CONGU' || handicapSystem === 'SMPLFD') {
        return playerHandicap;
    }

    const slope = getSlope(tee);
    let handicap = playerHandicap * slope / 113;
    const totalPar = getTotalPar(holesType, tee);
    const rating = getRating(tee, holesType);
    if (handicapSystem === 'SAGA') {
        handicap = handicap + (rating - totalPar);
    }
    if (handicapSystem === 'EGA') {
        if (playerHandicap > 36) {
            const handicap36 = 36 * slope / 113 + (rating - totalPar);
            const handicapDiff = handicap36 - 36;
            handicap = playerHandicap + handicapDiff;
        } else {
            handicap = handicap + (rating - totalPar);
        }
    }
    if (['WHS', 'WHS_AU', 'WHS_UK'].indexOf(handicapSystem) > -1) {
        // in accordance with https://www.golf.org.au/whs/
        if (!handicapAllowance) {
            if (handicapSystem === 'WHS_AU') {
                handicapAllowance = 93.0;
            } else if (handicapSystem === 'WHS_UK') {
                handicapAllowance = 95.0;
            } else {
                handicapAllowance = 100.0;
            }
        }
        handicap = (handicap + (rating - totalPar)) * handicapAllowance / 100.0;
    }
    // USGA, GA (no default % handicapAllowance)
    // console.log('handicapSystem:' + handicapSystem + ' playerHandicap: ' + playerHandicap+ ' handicap: ' + handicap + ' rating: ' + rating + ' totalPar: ' + totalPar + ' handicapAllowance: ' + handicapAllowance );
    return Number(handicap.toFixed(fractionDigitsDefault));
}

export function getTeeRatingString(tee?: Tee | null, holesType?: HolesType) {
    if (!tee) {
        return '';
    }
    const hs = tee.handicapSystem;
    const slope = getSlope(tee);
    const rating = getRating(tee, holesType);
    switch (hs) {
        case 'CONGU':
            return hs + ' Standard Scratch Score: ' + tee.conguSss;
        case 'GA':
            return hs + ' Scratch Rating: ' + rating.toFixed(1) + ' Slope: ' + slope;
        case 'SAGA':
            return hs + ' Standard Rating: ' + rating;
        default:
            return hs + ' Rating: ' + rating.toFixed(1) + ' Slope: ' + slope;
    }
}

export function getTeeName(tee?: Tee | null, holesType?: HolesType) {
    if (!tee) {
        return '';
    }
    const hs = tee.handicapSystem;
    const slope = getSlope(tee);
    const rating = getRating(tee, holesType);
    const name = tee.name;
    switch (hs) {
        case 'CONGU':
            return name + ' (' + tee.conguSss + ')';
        case 'SAGA':
            return name + ' (' + rating + ')';
        case 'GA':
        default:
            return name + ' (' + rating.toFixed(1) + '/' + slope + ')';
    }
}

export function getTeeInfo(tee?: Tee | null, holesType?: HolesType) {
    if (!tee) {
        return '';
    }
    const hs = tee.handicapSystem;
    const slope = getSlope(tee);
    const rating = getRating(tee, holesType);
    let res = 'Par: ' + tee.par.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    switch (hs) {
        case 'CONGU':
            res += '  Congu SSS: ' + tee.conguSss;
            break;
        case 'SAGA':
            res += '  Rating: ' + rating;
            break;
        case 'GA':
        default:
            res += '  Rating: ' + rating.toFixed(1) + '  Slope: ' + slope;
    }
    return res;
}

export function getTeeNameEx(tee?: Tee | null, holesType?: HolesType) {
    if (!tee) {
        return 'Tee not selected';
    }
    const name = tee.name;
    const hs = tee.handicapSystem;
    const slope = getSlope(tee);
    const rating = getRating(tee, holesType);
    switch (hs) {
        case 'CONGU':
            return name + ' tees (conguSss ' + tee.conguSss + ')';
        case 'SAGA':
            return name + ' tees (rating ' + rating.toFixed(1) + ')';
        case 'GA':
        default:
            return name + ' tees (rating ' + rating.toFixed(1) + ' slope ' + slope + ')';
    }
}

export function getTeeNameWg(tee?: Tee | null, holesType?: HolesType) {
    if (!tee) {
        return 'Tee not selected';
    }
    const name = tee.name;
    const hs = tee.handicapSystem;
    const slope = getSlope(tee);
    const rating = getRating(tee, holesType);
    const gender = tee.gender === 'male' ? '- men' : '- women';
    switch (hs) {
        case 'CONGU':
            return name + ' tees ' + gender + ' (conguSss ' + tee.conguSss + ')';
        case 'SAGA':
            return name + ' tees ' + gender + ' (rating ' + rating.toFixed(1) + ')';
        case 'GA':
        default:
            return name + ' tees ' + gender + ' (rating ' + rating.toFixed(1) + ' slope ' + slope + ')';
    }
}

export function compareTee(t1: Tee, t2: Tee): number {
    if (t1.handicapSystem !== t2.handicapSystem) {
        return t1.handicapSystem.localeCompare(t2.handicapSystem);
    }
    const gender1 = getGender(t1);
    const gender2 = getGender(t2);
    if (gender1 === gender2) {
        switch (t1.handicapSystem) {
            case 'CONGU':
                return t2.conguSss - t1.conguSss;
            default:
                const rating1 = getRating(t1);
                const rating2 = getRating(t2);
                return rating2 - rating1;
        }
    } else if (gender1 === 'male') {
        return -1;
    } else {
        return 1;
    }
}

export function equalTees(t1: Tee, t2: Tee): boolean {
    return compareTee(t1, t2) === 0 &&
        Utils.equalArrays(t1.handicap, t2.handicap) &&
        Utils.equalArrays(t1.par, t2.par) &&
        Utils.equalArrays(t1.len, t2.len);
}

export function hardestTee(tees?: Array<Tee | null | undefined>) {
    return tees ? tees.reduce((hardest, curr) => curr && (!hardest || hardest.rating < curr.rating) ? curr : hardest, tees[0]) : undefined;
}

export function uncombineTees(tees: Array<Tee>): Array<Array<Tee>> {
    const teesFront: Array<Tee> = [];
    const teesBack: Array<Tee> = [];
    tees.forEach(tee => {
        const parFront: Par[] = [];
        const parBack: Par[] = [];
        const lenFront: number[] | undefined = tee.len && tee.len.length ? [] : undefined;
        const lenBack: number[] | undefined = tee.len && tee.len.length ? [] : undefined;
        const handicapFront: number[] = tee.handicap ? [] : [];
        const handicapBack: number[] = tee.handicap ? [] : [];
        const handicap2Front: number[] | undefined = tee.handicap2 ? [] : undefined;
        const handicap2Back: number[] | undefined = tee.handicap2 ? [] : undefined;
        let hole = 0;
        for (; hole < NINE_HOLES; hole++) {
            parFront[hole] = tee.par[hole];
            handicapFront[hole] = (tee.handicap[hole] + 1) / 2;
            if (tee.handicap2 && tee.handicap2.length >= 9 && handicap2Front) {
                handicap2Front[hole] = (tee.handicap2[hole] + 1) / 2;
            }
            if (tee.len && lenFront) {
                lenFront[hole] = tee.len[hole];
            }
        }
        const teeFront = {
            id: tee.id,
            name: tee.name,
            slopeFront: 0,
            slopeBack: 0,
            slope: getFrontSlope(tee),
            ratingFront: 0,
            ratingBack: 0,
            rating: getFrontRating(tee),
            conguSss: tee.conguSss,
            handicapSystem: tee.handicapSystem,
            par: parFront,
            handicap: handicapFront
        } as Tee;
        if (tee.gender) {
            teeFront.gender = tee.gender;
        }
        if (lenFront) {
            teeFront.len = lenFront;
        }
        if (handicap2Front) {
            teeFront.handicap2 = handicap2Front;
        }
        teesFront.push(teeFront);
        for (; hole < MAX_HOLES; hole++) {
            parBack[hole - NINE_HOLES] = tee.par[hole];
            handicapBack[hole - NINE_HOLES] = tee.handicap[hole] / 2;
            if (tee.handicap2 && tee.handicap2.length >= 9 && handicap2Back) {
                handicap2Back[hole - NINE_HOLES] = tee.handicap2[hole] / 2;
            }
            if (tee.len && lenBack) {
                lenBack[hole - NINE_HOLES] = tee.len[hole];
            }
        }
        const teeBack = {
            id: tee.id,
            name: tee.name,
            slopeFront: 0,
            slopeBack: 0,
            slope: getBackSlope(tee),
            ratingFront: 0,
            ratingBack: 0,
            rating: getBackRating(tee),
            conguSss: tee.conguSss,
            handicapSystem: tee.handicapSystem,
            par: parBack,
            handicap: handicapBack,
        } as Tee;
        if (tee.gender) {
            teeBack.gender = tee.gender;
        }
        if (lenBack) {
            teeBack.len = lenBack;
        }
        if (handicap2Back) {
            teeBack.handicap2 = handicap2Back;
        }
        teesBack.push(teeBack);
    });
    return [teesFront, teesBack];
}

export function combineTees(tees1: Array<Tee>, tees2: Array<Tee>): Array<Tee> {
    const map2 = new Map<string, Tee>();
    tees2.forEach(t => map2.set(t.id, t));
    const teesCombined: Array<Tee> = [];
    for (const tee1 of tees1) {
        const tee2 = map2.get(tee1.id);
        if (!tee2) {
            continue;
        }
        const par: Par[] = [];
        const len: number[] = [];
        const handicap: number[] = [];
        const handicap2: number[] = [];
        let hole = 0;
        for (; hole < NINE_HOLES; hole++) {
            par[hole] = tee1.par[hole];
            handicap[hole] = 2 * tee1.handicap[hole] - 1;
            if (tee1.handicap2 && tee1.handicap2.length >= 9) {
                handicap2[hole] = 2 * tee1.handicap2[hole] - 1;
            }
            if (tee1.len) {
                len[hole] = tee1.len[hole] ?? 0;
            } else {
                len[hole] = 0;
            }
        }
        for (; hole < MAX_HOLES; hole++) {
            par[hole] = tee2.par[hole - NINE_HOLES];
            handicap[hole] = 2 * tee2.handicap[hole - NINE_HOLES];
            if (tee2.handicap2 && tee2.handicap2.length >= 9) {
                handicap2[hole] = 2 * tee2.handicap2[hole - NINE_HOLES];
            }
            if (tee2.len) {
                len[hole] = tee2.len[hole - NINE_HOLES] ?? 0;
            } else {
                len[hole] = 0;
            }
        }
        const tee = {
            id: tee1.id,
            name: tee1.name,
            slopeFront: tee1.slope,
            slopeBack: tee2.slope,
            slope: 0,
            ratingFront: tee1.rating,
            ratingBack: tee2.rating,
            rating: 0,
            conguSss: tee1.conguSss,
            handicapSystem: tee1.handicapSystem,
            par,
            len,
            handicap,
            handicap2,
        } as Tee;
        if (tee1.gender) {
            tee.gender = tee1.gender;
        }
        teesCombined.push(tee);
    }
    return teesCombined;
}

export function isWomens(teeId: string) {
    return teeId && teeId.startsWith('F-');
}

export function getGender(tee: Tee): Gender {
    if (tee.gender) {
        return tee.gender;
    }
    return isWomens(tee.id) ? 'female' : 'male';
}

export function isWomensTee(tee: Tee) {
    return getGender(tee) === 'female';
}

export function getTeeShortName(tee?: Tee | null) {
    if (!tee) {
        return '';
    }
    return tee.name + (isWomensTee(tee) ? ' - Women' : ' - Men');
}

export function getTeeLongName(tee?: Tee | null) {
    if (!tee) {
        return '';
    }
    return tee.name + (isWomensTee(tee) ? ' - Women' : ' - Men') + (tee.facilityName ? ` (${tee.facilityName})` : '');
}

export function toHolesType(holesType: string): HolesType {
    return parseInt(holesType, 10) as HolesType;
}

export function getHolesRangeName(holesType?: HolesType): string {
    switch (holesType) {
        case HOLES_9: return '9 holes';
        case HOLES_FRONT_9: return 'Front nine';
        case HOLES_BACK_9: return 'Back nine';
        default: return '18 holes';
    }
}

export function getHoleHandicapByHC(handicapSystem: HandicapSystem, hole: number, holesType?: HolesType, tee?: Tee | null, courseHandicap?: number) {
    courseHandicap = courseHandicap || 0;
    const courseHoleCount = holesType === HOLES_9 || holesType === HOLES_9_9 ? 9 : 18;
    let holeHandicap = 0;
    if (handicapSystem === 'WHS_AU') {
        if (courseHandicap > courseHoleCount && !!tee && !!tee.handicap2 && !!tee.handicap2[hole % courseHoleCount]) {
            holeHandicap = (tee && tee.handicap2 && tee.handicap2[hole % courseHoleCount]) - courseHoleCount || 0;
        } else {
            holeHandicap = (tee && tee.handicap && tee.handicap[hole % courseHoleCount]) || 0;
        }
    } else {
        holeHandicap = (tee && tee.handicap && tee.handicap[hole % courseHoleCount]) || 0;
    }
    if (holesType === HOLES_9_9) {
        if (hole < 9) {
            holeHandicap = 2 * holeHandicap - 1;
        } else {
            holeHandicap = 2 * holeHandicap;
        }
    }
    const sign = Math.sign(courseHandicap);
    const holesRange = getHolesRange(holesType);
    const totalHoles = holesRange.last - holesRange.first;
    const holeCount = holesRange.last - holesRange.first;
    if (totalHoles === 9 && tee?.handicap.length === 18) {
        holeHandicap = Math.round(holeHandicap / 2);
    }
    if (sign < 0) {
        holeHandicap = holeCount - holeHandicap + 1;
    }
    const handicap = courseHandicap > 0 ? Math.floor(courseHandicap / holeCount) : Math.ceil(courseHandicap / holeCount);
    return (Math.abs(courseHandicap % holeCount) >= holeHandicap) ? (handicap + sign) : handicap;
}

export function getHoleHandicap(hole: number, holesType?: HolesType, tee?: Tee | null, handicapSystem?: HandicapSystem, handicapIndex?: number, handicapAllowance?: number, playingHandicap?: number) {
    if (!handicapSystem) {
        if (!tee) {
            return 0;
        }
        handicapSystem = tee.handicapSystem;
    }
    return getHoleHandicapByHC(handicapSystem, hole, holesType, tee, playingHandicap ?? getPlayingHandicap(holesType, tee, handicapSystem, handicapIndex, handicapAllowance));
}

export function getGolferPlayingHandicap(golfer: Contact, eventOrRound: EventBase, competition: Competition, index?: number): number {
    if (golfer.handicapOverride) {
        return golfer.handicapOverride;
    } else {
        const handicapAllowance = getHandicapsAllowance(golfer, competition.scoring, index ?? 0);
        const tee = getTee(eventOrRound, competition, golfer.gender, golfer);
        const rangeIndex = getGolferRangeHandicap(eventOrRound.holesType, eventOrRound.handicapSystem, golfer.handicapIndex);
        return getPlayingHandicap(eventOrRound.holesType, tee, eventOrRound.handicapSystem, rangeIndex, handicapAllowance);
    } 
}

export function getPlayingHandicap(holesType?: HolesType, tee?: Tee | null, handicapSystem?: HandicapSystem, playerHandicap?: number, handicapAllowance?: number): number {
    // comments below to fix 181146165 Incorrect 9 hole playing handicap - example provided
    // const holesRange = getHolesRange(holesType);
    // const holeCount = holesRange.last - holesRange.first;
    const courseHandicap = getBasicCourseHandicap(holesType, tee, handicapSystem, playerHandicap, handicapAllowance);
    // if (holeCount === NINE_HOLES) {
    //    playingHandicap = courseHandicap / 2;
    // }
    const playingHandicap = Math.round(courseHandicap);
    // console.log('playerHandicap: ' + playerHandicap + '. courseHandicap: '+ courseHandicap + '. playingHandicap: '+ playingHandicap)    
    return playingHandicap;
}

export function getTeamPlayingHandicap(playingHandicaps: Array<number>): number {
    return Math.round(playingHandicaps.reduce((sum, cur) => sum += cur, 0));
}

export function getGolferRangeHandicap(holesType?: HolesType, _handicapSystem?: HandicapSystem, handicapIndex?: number) {
    if (!handicapIndex) {
        return 0;
    }
    const holesRange = getHolesRange(holesType);
    const holeCount = holesRange.last - holesRange.first;
    if (holeCount === NINE_HOLES) {
        return Math.round(handicapIndex * holeCount / MAX_HOLES * 10) / 10;
    } else {
        return handicapIndex;
    }
}

export function calcPlayingHandicap(eventOrRound: EventBase, golfer: Contact, golfers: Map<string, Contact>, competitions: Array<Competition>, teams: Map<string, Team>, basedOnAppCompetition: boolean = false) {
    if (golfer.handicapOverride) {
        return golfer.handicapOverride;
    }
    const competition = basedOnAppCompetition ? getAppMainCompetition(competitions) : getGolferMainCompetition(golfer, competitions, teams);
    let playingHandicap = 0;
    if (competition || golfer.tee) {
        const handicapInRange = getGolferRangeHandicap(eventOrRound.holesType, eventOrRound.handicapSystem, golfer.handicapIndex);
        const defaultHandicapAllowance = 100;
        if (competition) {
            const tee = getTee(eventOrRound, competition, golfer.gender, golfer);
            let handicapAllowance: number;
            const requiredTeam = isTeamFormat(competition.scoring) && competition.scoring.format !== ScoringFormatTeams.best_ball && getTeamOfContact(teams, golfer);
            if (requiredTeam) {
                const teamContacts = golfersOfTeam(requiredTeam, golfers);
                const playerIndex = teamContacts.indexOf(golfer);
                if (playerIndex === -1 || !competition.scoring.handicaps || competition.scoring.handicaps.length <= playerIndex) {
                    handicapAllowance = defaultHandicapAllowance;
                } else {
                    handicapAllowance = competition.scoring.handicaps[playerIndex];
                }
            } else {
                handicapAllowance = !!competition.scoring.handicaps && competition.scoring.handicaps.length > 0 ? competition.scoring.handicaps[0] : defaultHandicapAllowance;
            }
            playingHandicap = getPlayingHandicap(eventOrRound.holesType, tee, eventOrRound.handicapSystem, handicapInRange, handicapAllowance);
        } else {
            playingHandicap = getPlayingHandicap(eventOrRound.holesType, golfer.tee, eventOrRound.handicapSystem, handicapInRange, defaultHandicapAllowance);
        }
    }
    return playingHandicap;
}

export function getHandicapsAllowance(golfer: Contact | Team | ContactInfo, scoring: ScoringData, index: number) {
    if (golfer.handicapAllowance) {
        return golfer.handicapAllowance;
    } else {
        return scoring && scoring.handicaps ? scoring.handicaps[index] || 0 : 100;
    }
}

export function fixTeamHandicapAndGender(team: Team, golfers: Map<string, Contact>) {
    team.handicapIndex = Utils.round(Utils.evalAvg(team.contactIds.map(cId => golfers.get(cId)?.handicapIndex || 0)), 1);
    let evalGender: Gender | undefined;
    for (const contactId of team.contactIds) {
        const golfer = golfers.get(contactId);
        if (golfer && !golfer.hidden) {
            if (!evalGender) {
                evalGender = golfer.gender;
            } else if (evalGender !== golfer.gender) {
                evalGender = undefined;
                break;
            }
        }
    }
    team.gender = evalGender;
}

export function fixTeamsHandicapAndGender(teams: Map<string, Team>, golfers: Map<string, Contact>) {
    teams.forEach(team => fixTeamHandicapAndGender(team, golfers));
}

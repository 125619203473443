import * as React from 'react';
import { Fab } from '@mui/material';
import { RoutingButton } from './Routing';
import { useUserAware, } from '../auth/Auth';
import { useAppStyles } from '../styles';
import { ITEM_NEW_EVENT_ID, Urls } from '../util/config';
import { FlexCenter } from 'src/common/Misc';
import { StarIcon } from 'src/common/Icons';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AppButton from 'src/common/components/AppButton';
import NewEventDialog from 'src/event/list/NewEventDialogDetailed';
import DuplicateEventDialog from 'src/event/list/DuplicateEventDialog';
import { Event } from 'src/types/EventTypes';
import { useCookies } from 'react-cookie';
import { AccountActions, accountAction } from 'src/auth/StripeRedirect';
import ProPlanDialog from 'src/auth/ProPlanDialog';

function NavigationMenu() {
    const [addDialogOpened, setAddDialogOpened] = React.useState(false);
    const [duplicateEvent, setDuplicateEvent] = React.useState<Event | undefined>();
    const [proBanner, showProBanner] = React.useState(false);
    const [_cookies, _setCookie, removeCookie] = useCookies(['announcementShown']);
    const classes = useAppStyles();
    const userAware = useUserAware();
    const { loginStatus, effectiveUserId, hasPro } = userAware;
    if (loginStatus !== 'Logged') {
        return null;
    }
    const handleDuplicateEvent = () => {
        setAddDialogOpened(false);
        setDuplicateEvent({ id: '' } as Event);
    }
    const handleCloseDialog = (addedId?: string) => {
        setAddDialogOpened(false);
        setDuplicateEvent(undefined);
        if (addedId) {
            localStorage.setItem(ITEM_NEW_EVENT_ID, addedId);
            window.location.replace(`/events/${addedId}`);
        }
    }
    const handleClosePorPlan = (name?: AccountActions) => {
        showProBanner(false);
        accountAction(userAware, name);
    }
    const path = window.location.pathname;
    return <>
        <span color="inherit" style={{ flex: 1, fontSize: 12 }} />
        {!hasPro &&
            <AppButton
                color="inherit"
                sx={{ minWidth: 104 }}
                className={classes.toolbarButtonCommon + ' ' + classes.toolbarButtonPro}
                onClick={() => showProBanner(true)}>
                <FlexCenter><StarIcon sx={{ width: '16px', paddingRight: '4px' }} />Get Pro</FlexCenter>
            </AppButton>}
        <Fab href={Urls.helpURL}
            target="help"
            variant="extended"
            className={classes.toolbarButtonCommon + ' ' + classes.toolbarButton}>
            &nbsp;HELP&nbsp;<OpenInNewIcon />&nbsp;&nbsp;
        </Fab>        
        {effectiveUserId &&
            <RoutingButton
                color="inherit"
                variant="extended"
                to="/sup"
                className={classes.toolbarButtonCommon + ' ' + (path.indexOf('/sup') === 0 ? classes.toolbarButtonActive : classes.toolbarButton)}>
                SUP
            </RoutingButton>}
        <span style={{ width: 10, height: 10, position: 'absolute', left: 1, top: 1 }} onClick={() => removeCookie('announcementShown', { path: '/' })} />
        {addDialogOpened &&
            <NewEventDialog
                noDuplicate
                handleClose={handleCloseDialog}
                handleDuplicateEvent={handleDuplicateEvent}
            />}
        {duplicateEvent &&
            <DuplicateEventDialog
                event={duplicateEvent}
                handleClose={handleCloseDialog}
            />}
        {proBanner &&
            <ProPlanDialog handleClose={handleClosePorPlan} />}
    </>;
}

export default NavigationMenu;

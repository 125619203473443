import * as React from 'react';
import { Emails, MON_DATE_FORMAT, Urls } from 'src/util/config';
import { showAlert2, showProgress } from 'src/redux/ReduxConfig';
import { formatDateUniversal, prepareRequest } from 'src/event/Event';
import { UserAware } from './Auth';
import { formatCurrency, getServerErrorMessage } from 'src/util/utility';
import axios from 'axios';
import { ListSubscriptionsRequest, ListSubscriptionsResponse, Subscription } from 'src/types/EventTypes';
import { XSMobileDialog } from 'src/common/dialog/MobileDialog';
import DialogAppBar from 'src/common/dialog/DialogAppBar';
import AppButton from 'src/common/components/AppButton';
import { CircularProgress, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import { AccountActions, accountAction, stripeCancel } from './StripeRedirect';
import { StarIcon } from 'src/common/Icons';
import ProPlanDialog from './ProPlanDialog';
import { useAppStyles } from 'src/styles';

export function ManageSubscriptionDialog({ userAware, onClose }: { userAware: UserAware, onClose: VoidFunction }) {
    const [subscriptions, setSubscriptions] = React.useState<Array<Subscription>>([]);
    const [loading, setLoading] = React.useState(0);
    const [error, setError] = React.useState<React.ReactNode>('');
    const [proBanner, showProBanner] = React.useState(false);
    const classes = useAppStyles();
    const handleClosePorPlan = (name?: AccountActions) => {
        showProBanner(false);
        accountAction(userAware, name);
    }
    React.useEffect(() => {
        async function loadSubscription() {
            if (loading > 0) {
                return;
            }
            setLoading(1);
            setSubscriptions([]);
            const hideProgress = showProgress('signInWithToken');
            try {
                const request: ListSubscriptionsRequest = {
                    token: '',
                    action: '',
                    source: '',
                    userId: userAware.workingUserId!
                };
                await prepareRequest(request);
                const { subscriptions } = (await axios.post(Urls.listSubscriptions, request)).data as ListSubscriptionsResponse;
                setSubscriptions(subscriptions);
                const cancelingSubscription = subscriptions.find(subscription => subscription.status === 'canceling');
                if (cancelingSubscription) {
                    setError(<>
                        Your subscription was canceled recently. You will have access to Events Pro until <b>{formatDateUniversal(cancelingSubscription.currentPeriodEnd, MON_DATE_FORMAT)}</b> and the free version thereafter.
                    </>);
                }
                hideProgress();
            } catch (err) {
                hideProgress(getServerErrorMessage(err));
                setError('Failed to load subscription info: ' + getServerErrorMessage(err));
            }
            setLoading(2);
        }
        loadSubscription();
    })
    function cancelSubscription(subscription: Subscription) {
        showAlert2(<>
            We're sorry to see you go. You will have access to Events Pro until <b>{formatDateUniversal(subscription.currentPeriodEnd, MON_DATE_FORMAT)}</b> and the free version thereafter.
            <br />
            Confirm to Cancel subscription?
        </>, [
            {
                title: 'Keep subscription',
                color: 'secondary'
            },
            {
                title: 'Cancel subscription',
                action: async () => {
                    await stripeCancel(userAware);
                    setSubscriptions([]);
                }
            },
        ]);
    }
    const canceling = subscriptions?.find(subscription => subscription.status === 'canceling');
    const active = canceling || subscriptions?.find(subscription => subscription.status === 'active') || userAware.grant > Date.now();
    return <>
        <XSMobileDialog fullWidth open onClose={onClose} maxWidth={'md'}>
            <DialogAppBar label={'Manage subscription'} close={onClose} />
            <DialogContent>
                <Typography variant='subtitle1'>Subscription details</Typography>
                <Typography>{error}</Typography>
                {userAware.grant > Date.now() && <div style={{ color: "green", fontWeight: 600 }}>
                    You are using the Pro version, granted till {formatDateUniversal(userAware.grant, MON_DATE_FORMAT)}.
                </div>}
                {subscriptions.map(subscription =>
                    <div key={subscription.id} style={{ fontFamily: 'roboto' }}>
                        <span style={{ fontWeight: 600 }}>{subscription.name}</span> &nbsp;
                        {formatCurrency(subscription.currency, subscription.price / 100)}/{subscription.interval} &nbsp;
                        <span style={{ fontWeight: 300 }}>({subscription.status}) &nbsp;</span>
                        <br />
                        {formatDateUniversal(subscription.currentPeriodStart, MON_DATE_FORMAT)} - {formatDateUniversal(subscription.currentPeriodEnd, MON_DATE_FORMAT)} &nbsp;
                        <br />
                        <Typography>Payments</Typography>
                        {subscription.invoices.map(inv => <span key={inv.id}>
                            {formatDateUniversal(inv.datePaid, MON_DATE_FORMAT)} &nbsp;
                            {formatCurrency(inv.currency, inv.amountPaid / 100)} &nbsp;
                            <a href={inv.receiptUrl} target='_blank'>Receipt</a> <br />
                        </span>)}
                        {subscription.status === 'active' &&
                            <AppButton
                                color="secondary"
                                variant="outlined"
                                onClick={() => cancelSubscription(subscription)}
                            >
                                Cancel subscription
                            </AppButton>}
                    </div>)}
                {loading === 1 &&
                    <span style={{ color: "grey" }}>
                        <CircularProgress size={16} /> Loading subscription details...
                    </span>}
                {(!active && loading !== 1) && <>
                    <Typography>
                        You are using the free version, would you like to upgrade to pro?
                    </Typography>
                    <AppButton
                        sx={{ minWidth: '100px' }}
                        className={classes.toolbarButtonCommon + ' ' + classes.toolbarButtonPro2}
                        onClick={() => showProBanner(true)}
                    >
                        <StarIcon sx={{ width: '16px', paddingRight: '4px' }} /> Get Pro
                    </AppButton>
                </>}
                <br />
                <br />
                <Divider />
                <Typography>
                    For questions or to share your experience please <a href={`mailto:${Emails.supportEmail}`}>email</a> us
                </Typography>
            </DialogContent>
            <DialogActions>
                <AppButton onClick={onClose}>
                    Close
                </AppButton>
            </DialogActions>
            {proBanner &&
                <ProPlanDialog handleClose={handleClosePorPlan} />}
        </XSMobileDialog>
    </>;
};


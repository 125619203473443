import * as React from 'react';
import { StarIcon } from './Icons';
import { AppColors } from 'src/main/Theme';

const ProBadge = ({ small, inv, placeholder, marginRight, disabled }: { small?: boolean, inv?: boolean, placeholder?: boolean, marginRight?: string | number,  disabled?: boolean }) => {
    return (
        <span style={{
            visibility: placeholder ? 'hidden' : 'visible',
            display: 'inline-flex',
            color: inv ? (disabled ? AppColors.webGreyGreen500 : AppColors.webGreen500) : (disabled ? AppColors.webGrey100 : AppColors.white),
            backgroundColor: inv ? (disabled ? AppColors.webGrey100 : AppColors.white) : (disabled ? AppColors.webGreyGreen300 : AppColors.webGreen300),
            borderRadius: small ? 10 : 14,
            fontSize: small ? 11 : 12,
            fontWeight: 500,
            height: small ? 20 : 28,
            width: small ? 48 : 60,
            marginRight: marginRight,
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'none'
        }}>
            <StarIcon sx={{ width: small ? '14px' : '16px', paddingRight: '4px' }} />
            <span>Pro</span>
        </span>
    );
}

export default ProBadge;

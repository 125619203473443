import * as React from 'react';
import { Box } from '@mui/system';
import { AppBar, MenuItem, TextField, Toolbar, Typography } from '@mui/material';
import { HANDICAP_SYSTEM_IDS, HANDICAP_SYSTEM_NAMES, HandicapSystem, Tee } from 'src/types/EventTypes';
import { getPlayingHandicap, getTeamPlayingHandicap } from 'src/scoring/handicap';
import { Flex } from 'src/common/Misc';
import Logo from '../common/Logo';
import { pushUrl } from 'src/redux/ReduxConfig';

const TEXT_FIELD_SIZE = 'small';
const TEXT_FIELD_VARIANT = 'filled';
const FISRT_ELEM_FIELD_WIDTH = 80;
const ELEM_FIELD_WIDTH = 100;
const ELEM_FIELD_MARGIN = 8;

const HandicapCalc = () => {
    const [handicap1, setHandicap1] = React.useState(2.3);
    const [handicap2, setHandicap2] = React.useState(10.5);
    const [allowance1, setAllowance1] = React.useState(100.0);
    const [allowance2, setAllowance2] = React.useState(100.0);
    const [rating, setRating] = React.useState(63.7);
    const [slope, setSlope] = React.useState(101);
    const [handicapSystem, setHandicapSystem] = React.useState('WHS' as HandicapSystem);
    const holesType = 1;
    const tee: Tee = {
        id: '',
        name: '',
        rating,
        ratingFront: 0,
        ratingBack: 0,
        slope,
        slopeFront: 0,
        slopeBack: 0,
        conguSss: 0,
        handicapSystem,
        handicap: [13, 5, 3, 7, 9, 15, 11, 17, 1, 14, 6, 2, 8, 10, 18, 12, 16, 4],
        par: [4, 5, 4, 4, 4, 3, 4, 3, 4, 4, 5, 4, 4, 4, 3, 4, 3, 4]
    };
    const playingHandicap1 = getPlayingHandicap(holesType, tee, handicapSystem, handicap1, allowance1);
    const playingHandicap2 = getPlayingHandicap(holesType, tee, handicapSystem, handicap2, allowance2);
    return <>
        <AppBar position="static">
            <Toolbar variant="dense" sx={{ height: 56 }}>
                <Logo onclk={() => pushUrl('/events')} />
            </Toolbar>
        </AppBar>
        <Box style={{ margin: 20 }}>
            <Flex>
                <Typography style={{ width: FISRT_ELEM_FIELD_WIDTH, margin: ELEM_FIELD_MARGIN }}>Handicap System</Typography>
                <TextField
                    select
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    style={{ width: ELEM_FIELD_WIDTH * 5, margin: ELEM_FIELD_MARGIN }}
                    value={handicapSystem}
                    onChange={e => setHandicapSystem(e.target.value as HandicapSystem)}>
                    {HANDICAP_SYSTEM_NAMES.map((hcpType, idx) =>
                        <MenuItem
                            key={hcpType}
                            value={HANDICAP_SYSTEM_IDS[idx]}>
                            {hcpType}
                        </MenuItem>)}
                </TextField>
            </Flex>
            <Flex>
                <Typography style={{ width: FISRT_ELEM_FIELD_WIDTH, margin: ELEM_FIELD_MARGIN }}>Tee rating</Typography>
                <TextField
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    style={{ width: ELEM_FIELD_WIDTH, margin: ELEM_FIELD_MARGIN }}
                    value={rating}
                    onChange={e => setRating(parseFloat(e.target.value) || 0)} />
            </Flex>
            <Flex>
                <Typography style={{ width: FISRT_ELEM_FIELD_WIDTH, margin: ELEM_FIELD_MARGIN }}>Tee slope</Typography>
                <TextField
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    style={{ width: ELEM_FIELD_WIDTH, margin: ELEM_FIELD_MARGIN }}
                    value={slope}
                    onChange={e => setSlope(parseFloat(e.target.value) || 0)} />
            </Flex>
            <Flex>
                <Typography style={{ width: FISRT_ELEM_FIELD_WIDTH, margin: ELEM_FIELD_MARGIN }}>Tee par</Typography>
                <span style={{ margin: ELEM_FIELD_MARGIN }}><pre>{tee.par.join('  ')}</pre></span>
            </Flex>
            <Flex>
                <Typography style={{ width: FISRT_ELEM_FIELD_WIDTH, margin: ELEM_FIELD_MARGIN }}>Golfer 1</Typography>
                <TextField
                    label="Handicap"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    style={{ width: ELEM_FIELD_WIDTH, margin: ELEM_FIELD_MARGIN }}
                    value={handicap1}
                    onChange={e => setHandicap1(parseFloat(e.target.value) || 0)} />
                <TextField
                    label="Allowance"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    style={{ width: ELEM_FIELD_WIDTH, margin: ELEM_FIELD_MARGIN }}
                    value={allowance1}
                    onChange={e => setAllowance1(parseFloat(e.target.value) || 0)} />
                <Typography style={{ margin: ELEM_FIELD_MARGIN }}>Playing Handicap: <b>{playingHandicap1}</b></Typography>
            </Flex>
            <Flex>
                <Typography style={{ width: FISRT_ELEM_FIELD_WIDTH, margin: ELEM_FIELD_MARGIN }}>Golfer 2</Typography>
                <TextField
                    label="Handicap"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    style={{ width: ELEM_FIELD_WIDTH, margin: ELEM_FIELD_MARGIN }}
                    value={handicap2}
                    onChange={e => setHandicap2(parseFloat(e.target.value) || 0)} />
                <TextField
                    label="Allowance"
                    size={TEXT_FIELD_SIZE}
                    variant={TEXT_FIELD_VARIANT}
                    style={{ width: ELEM_FIELD_WIDTH, margin: ELEM_FIELD_MARGIN }}
                    value={allowance2}
                    onChange={e => setAllowance2(parseFloat(e.target.value) || 0)} />
                <Typography style={{ margin: ELEM_FIELD_MARGIN }}>Playing Handicap: <b>{playingHandicap2}</b></Typography>
            </Flex>
            <Flex>
                <Typography style={{ margin: ELEM_FIELD_MARGIN }}>Golfer 1 and Golfer 2 Team Playing Handicap: <b>{getTeamPlayingHandicap([playingHandicap1, playingHandicap2])}</b></Typography>
            </Flex>

        </Box>
    </>;
};
export default HandicapCalc;

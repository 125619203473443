import React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { Event, EventLog } from '../../../types/EventTypes';
import { List, ListItem, Divider, ListItemButton } from '@mui/material';
import { Container, Item, Spacing } from '../../../common/Misc';
import { showProgress } from '../../../redux/ReduxConfig';
import * as Backend from '../../../util/firebase';
import { formatDateTime } from '../../../util/utility';
import AppButton from '../../../common/components/AppButton';
import ButtonBar from '../../../common/components/ButtonBar';

interface State {
    pageIndex: number;
    pageQueries: Map<number, Backend.Query>;
    pageTimeStamps: Map<number, number>;
    logs: Array<EventLog>;
}

type Props = { event: Event } & WithStyles<typeof styles>;

const ITEMS_PER_PAGE = 100;

class ChangelogTab extends React.Component<Props, State> {
    private unsubscribe: () => void;
    constructor(props: Props) {
        super(props);
        this.state = {
            pageIndex: 0,
            pageQueries: new Map(),
            pageTimeStamps: new Map([[0, 0]]),
            logs: new Array<EventLog>()
        };
        const query = Backend.query(Backend.eventLogsDb(this.props.event.id), Backend.orderBy('datetime', 'desc'), Backend.limit(ITEMS_PER_PAGE));
        const hideProgress = showProgress('ChangelogTab query');
        this.unsubscribe = Backend.onSnapshot(query, data => setTimeout(() => {
            hideProgress();
            const dataArray = Backend.toArray<EventLog>(data);
            this.onLogs(dataArray);
        }));
        this.state.pageQueries.set(0, query);
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    private onLogs = (logs: Array<EventLog>) => {
        this.setState({ logs });
    }

    private onPrev = () => {
        const { pageQueries } = this.state;
        const pageIndex = this.state.pageIndex - 1;
        const query = pageQueries.get(pageIndex);
        if (query) {
            const hideProgress = showProgress('ChangelogTab onPrev');
            this.unsubscribe = Backend.onSnapshot(query, data => setTimeout(() => {
                hideProgress();
                const dataArray = Backend.toArray<EventLog>(data);
                this.onLogs(dataArray);
            }));
        } else {
            this.onLogs([]);
        }
        this.setState({ pageIndex });
    }

    private onNext = () => {
        const { logs, pageTimeStamps, pageQueries } = this.state;
        const timeStamp = logs[logs.length - 1].datetime;
        const pageIndex = this.state.pageIndex + 1;
        pageTimeStamps.set(pageIndex + 1, timeStamp);
        const query = Backend.query(Backend.eventLogsDb(this.props.event.id), Backend.orderBy('datetime', 'desc'), Backend.startAfter(timeStamp), Backend.limit(ITEMS_PER_PAGE));
        const hideProgress = showProgress('ChangelogTab onNext');
        pageQueries.set(pageIndex, query);
        this.unsubscribe = Backend.onSnapshot(query, data => setTimeout(() => {
            hideProgress();
            const dataArray = Backend.toArray<EventLog>(data);
            this.onLogs(dataArray);
        }));
        this.setState({ pageIndex: pageIndex });
    }

    private Changelog = () => {
        const { classes } = this.props;
        const { logs, pageIndex } = this.state;
        return (
            <div className={classes.listRootGrey}>
                <List disablePadding>
                    <Spacing height={8} />
                    <ListItem key='logHeader' className={classes.listItemHeaderWhite}>
                        <Container wrap="nowrap">
                            <Item xs={2} noWrap variant="body2">Date/Time</Item>
                            <Item xs={2} noWrap variant="body2">Source</Item>
                            <Item xs={2} noWrap variant="body2">Action</Item>
                            <Item xs={6} noWrap variant="body2">Details</Item>
                        </Container>
                    </ListItem>
                    <Divider />
                    <Divider />
                    {Array.from(logs.values()).map(log => (
                        <React.Fragment key={log.id}>
                            <ListItemButton className={classes.listItem}>
                                <Container wrap="nowrap">
                                    <Item xs={2} noWrap variant="body2">{formatDateTime(log.datetime)}</Item>
                                    <Item xs={2} noWrap variant="body2">{log.source}</Item>
                                    <Item xs={2} noWrap variant="body2">{log.action}</Item>
                                    <Item xs={6} noWrap variant="body2">{log.details}</Item>
                                </Container>
                            </ListItemButton><Divider />
                        </React.Fragment>
                    ))}
                </List>
                <ButtonBar margin>
                    <AppButton color="secondary" onClick={this.onPrev} disabled={pageIndex < 1}>Recent</AppButton>
                    <AppButton color="secondary" onClick={this.onNext} disabled={logs.length < ITEMS_PER_PAGE}>Previous</AppButton>
                </ButtonBar>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                {<this.Changelog />}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ChangelogTab);


import * as React from 'react';
import { Drawer, Box, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Collapse, Badge, Divider } from '@mui/material';
import { pushUrl } from '../../redux/ReduxConfig';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useAppState } from 'src/main/App';
import { appToolbarHeight } from 'src/styles';
import { useCookies } from 'react-cookie';
import { useUserAware } from 'src/auth/Auth';

export interface AppDrawerItem {
    id: string;
    url?: string;
    opened?: boolean;
    marked?: boolean;
    divider?: boolean;
    unselectable?: boolean;
    name?: React.ReactNode;
    icon?: React.ReactNode;
    element?: React.ReactNode;
    secondary?: React.ReactNode;
    children?: Array<AppDrawerItem>;
}

function isExternal(url: string) {
    console.log('isExternal', url)
    return url.startsWith('http') && new URL(url).origin !== location.origin;
}

function DrawerElemText({ tab }: { tab: AppDrawerItem }) {
    return (
        <ListItem disablePadding>
            {tab.icon && <ListItemIcon sx={{ minWidth: '32px' }}>{tab.icon}</ListItemIcon>}
            {tab.name && <ListItemText primary={tab.name} primaryTypographyProps={{ fontSize: '16px', fontWeight: '600', lineHeight: '150%' }} />}
            {tab.element}
        </ListItem>
    );
}

function DrawerElemChildren({ tab, open, autoClose }: { tab: AppDrawerItem, open?: boolean, autoClose?: VoidFunction }) {
    return (
        <Collapse in={open} timeout="auto">
            {tab.children?.map((subtab, idx) => {
                const url = (tab.url ?? '') + subtab.url;
                const click = () => {
                    if (autoClose) {
                        autoClose();
                    }
                    if (isExternal(url)) {
                        window.open(url, 'help');
                    } else {
                        pushUrl(url);
                    }
                };
                return (
                    <ListItem key={idx} sx={{ padding: 0 }}>
                        <ListItemButton
                            onClick={click}
                            selected={!subtab.unselectable && location.pathname === url}
                        >
                            <ListItemIcon sx={{ minWidth: '32px' }} />
                            <ListItemText
                                primary={<Badge color="error" overlap="rectangular" variant="dot" invisible={!subtab.marked} sx={{
                                    [`& .MuiBadge-badge`]: { right: -5 }
                                }}>
                                    {subtab.name}
                                </Badge>}
                                primaryTypographyProps={{ textTransform: 'uppercase' }}
                            />
                            {subtab.secondary && <span style={{ color: 'grey', fontSize: 13 }}>{subtab.secondary}</span>}
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </Collapse>
    );
}

function tabsSet(_parent: AppDrawerItem | null, tabs: Array<AppDrawerItem>, set: Set<string>) {
    tabs.forEach(tab => {
        if (tab.opened) {
            set.add(tab.id);
        }
        if (tab.children) {
            tabsSet(tab, tab.children, set);
        }
    });
    return set;
}

export default function AppDrawer({ tabs, width, mobileOnly }: { tabs: Array<AppDrawerItem>, width: number, mobileOnly?: boolean }) {
    const [opens, setOpens] = React.useState(tabsSet(null, tabs, new Set<string>()));
    const appState = useAppState();
    const userAware = useUserAware();
    const [cookies] = useCookies(['announcementShown']);
    const announcementShown = parseInt(cookies.announcementShown);
    const showAnnouncement = userAware.announcement?.text && announcementShown != userAware.announcement?.timestamp;
    const setOpen = (key: string, open: boolean) => {
        setOpens(prevSet => {
            const newSet = new Set(prevSet);
            if (open) {
                newSet.add(key);
            } else {
                newSet.delete(key);
            }
            return newSet;
        });
    };
    function DrawerElem({ tab, autoClose }: { tab: AppDrawerItem, autoClose?: VoidFunction }) {
        const click = () => {
            if (tab.children) {
                setOpen(tab.id, !opens.has(tab.id))
            } else if (tab.url) {
                if (autoClose) {
                    autoClose();
                }
                if (isExternal(tab.url)) {
                    window.open(tab.url, 'help');
                } else {
                    pushUrl(tab.url);
                }
            }
        };
        return (
            <ListItem sx={{ padding: 0 }}>
                <ListItemButton
                    onClick={click}
                    selected={!tab.unselectable && location.pathname === tab.url}
                >
                    {tab.icon && <ListItemIcon sx={{ minWidth: '32px' }}>{tab.icon}</ListItemIcon>}
                    {tab.name && <ListItemText primary={tab.name} primaryTypographyProps={{ textTransform: 'uppercase' }} />}
                    {tab.element}
                    {tab.children &&
                        (opens.has(tab.id) ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
            </ListItem>
        );
    }
    const handleDrawerClose = () => {
        appState.setIsClosingDrawer(true);
        appState.setMobileDrawerOpen(false);
    };
    const handleDrawerTransitionEnd = () => {
        appState.setIsClosingDrawer(false);
    };
    const content = <>
        <List>
            <Box sx={{ height: appToolbarHeight }} />
            {showAnnouncement && <Box sx={{ height: 28 }} />}
            {tabs.map((tab, idx) =>
                <div key={idx}>
                    {tab.divider && <Divider />}
                    {!tab.divider && tab.url &&
                        <DrawerElem tab={tab} autoClose={handleDrawerClose} />}
                    {!tab.divider && !tab.url &&
                        <DrawerElemText tab={tab} />}
                    {tab.children &&
                        <DrawerElemChildren tab={tab} open={opens.has(tab.id)} autoClose={handleDrawerClose} />}
                </div>)}
        </List>
    </>;
    return <>
        <Drawer
            variant="temporary"
            open={appState.mobileDrawerOpen}
            onClose={handleDrawerClose}
            onTransitionEnd={handleDrawerTransitionEnd}
            sx={{
                display: { xs: 'block', sm: 'block', md: 'none' },
                [`& .MuiDrawer-paper`]: { width, boxSizing: 'border-box' }
            }}
        >
            {content}
        </Drawer >
        {!mobileOnly && <Drawer
            open
            variant="permanent"
            sx={{
                width,
                flexShrink: 0,
                display: { xs: 'none', sm: 'none', md: 'block' },
                [`& .MuiDrawer-paper`]: { width, boxSizing: 'border-box' }
            }}
        >
            {content}
        </Drawer>}
    </>;
}

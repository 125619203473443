import * as React from 'react';
import { StarIcon } from './Icons';
import { AppColors } from 'src/main/Theme';
import { ListItem, Typography } from '@mui/material';
import AppButton from './components/AppButton';
import { useAppStyles } from 'src/styles';
import ProPlanDialog from 'src/auth/ProPlanDialog';
import { AccountActions, accountAction } from 'src/auth/StripeRedirect';
import { useUserAware } from 'src/auth/Auth';

const ProNotice = ({ children }: { children: React.ReactNode }) => {
    const [proBanner, showProBanner] = React.useState(false);
    const classes = useAppStyles();
    const userAware = useUserAware();
    const handleClosePorPlan = (name?: AccountActions) => {
        showProBanner(false);
        accountAction(userAware, name);
    }
    return (
        <ListItem className={classes.accountSettingsNotice} sx={{ backgroundColor: AppColors.webGreen100 }}>
            <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
                {children}
            </Typography>
            <span style={{ flex: '1 1 0%' }} />
            <AppButton
                sx={{ minWidth: '100px' }}
                className={classes.toolbarButtonCommon + ' ' + classes.toolbarButtonPro2}
                onClick={() => showProBanner(true)}
            >
                <StarIcon sx={{ width: '16px', paddingRight: '4px' }} /> Get Pro
            </AppButton>
            {proBanner &&
                <ProPlanDialog handleClose={handleClosePorPlan} />}
        </ListItem>
    );
}

export default ProNotice;

import { Checkbox, ClickAwayListener, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, ListItemButton, Paper, Popper, TextField, Typography } from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { BkCloseButton, LocationIcon } from 'src/common/Icons';
import { DateRangeIcon } from '@mui/x-date-pickers';
import MaterialDate from 'src/common/MaterialDate';
import { Flex, Spacing } from 'src/common/Misc';
import AppButton from 'src/common/components/AppButton';
import DialogAppBar from 'src/common/dialog/DialogAppBar';
import { XSMobileDialog } from 'src/common/dialog/MobileDialog';
import { eventBadgeUrl, formatEventDate, prepareRequest } from 'src/event/Event';
import { AppColors } from 'src/main/Theme';
import { NoticeElement, showProgress } from 'src/redux/ReduxConfig';
import { NEW_EVENT_ELEM_WIDTH, useAppStyles } from 'src/styles';
import { ACTION_EVENT_DUPLICATION, Event, EventDuplicateRequest, EventDuplicateResponse, Portal, Round, getCurrentCourseName, getEventNewDate, isEventNotDeleted } from 'src/types/EventTypes';
import { EVENT_DATETIME_FORMAT_DLG, Urls } from 'src/util/config';
import { dbgLog } from 'src/util/utility';
import * as Backend from 'src/util/firebase';
import ArcStepper from './ArcStepper';
import { processEnterKey } from 'src/util/react_utils';
import { useUserAware } from 'src/auth/Auth';
import ProBadge from 'src/common/ProBadge';
import { AccountActions, accountAction } from 'src/auth/StripeRedirect';
import ProPlanDialog from 'src/auth/ProPlanDialog';

type Props = {
    event: Event;
    workingUserId: string;
    handleClose: (addedId?: string) => void;
}

interface State {
    event: Event;
    events?: Array<Event>;
    page: number;
    showEvents: boolean;
    req: EventDuplicateRequest;
    eventPortals: Map<string, Portal>;
}

export type EventItemProps = {
    event: Event,
    rounds: Array<Round>;
    portal: Portal,
    portalLoaded: boolean,
    stepsTotal: number,
    stepsCompleted: number,
    handleItemClick: (event: Event, copyEvent: boolean) => void;
};

const RowEventItem = React.forwardRef(((props: EventItemProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const classes = useAppStyles();
    const { event, rounds, handleItemClick } = props;
    const { portal, portalLoaded } = props;
    let buttonClicked = false;
    const click = (e: React.MouseEvent, copyEvent: boolean) => {
        if (buttonClicked) {
            return;
        }
        e.preventDefault();
        buttonClicked = true;
        handleItemClick(event, copyEvent);
        setTimeout(() => buttonClicked = false);
    };
    const arcStepper = <span style={{ paddingRight: 6 }}>
        <ArcStepper
            badgeUrl={portalLoaded ? eventBadgeUrl(event, portal) : ''}
            stepsTotal={0}
            stepsCompleted={0}
            size={60}
        />
    </span>;
    return <ListItemButton className={classes.listItem1} ref={ref} id={event.id} onClick={e => click(e, false)}>
        {arcStepper}
        <Grid container spacing={1} sx={{ width: '100%' }}>
            <Grid item xs={12}>
                <Typography variant="body1" className={classes.boldText}>{event.name}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2">
                    <Flex>
                        <DateRangeIcon className={classes.textIcon + ' ' + classes.secondaryText} />
                        {formatEventDate(event, rounds)}
                    </Flex>
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2" className={classes.secondaryText} noWrap>
                    <Flex>
                        {event.course && <LocationIcon className={classes.textIcon} />}
                        {getCurrentCourseName(event)}
                    </Flex>
                </Typography>
            </Grid>
        </Grid>
    </ListItemButton>;
}));

class DuplicateEventDialog extends React.Component<Props, State> {
    state: State = {
        event: this.props.event,
        showEvents: false,
        eventPortals: new Map(),
        page: this.props.event.id ? 2 : 1,
        req: {
            action: ACTION_EVENT_DUPLICATION,
            token: '',
            source: '',
            eventId: this.props.event.id,
            eventName: this.props.event.name + ' Copy',
            eventDate: getEventNewDate().getTime(),
            copyGolfers: true,
            copyTeams: true,
            copySchedule: true,
            copyCompetitions: true,
            copyPayoutSettings: true,
            notificationLess: false
        }
    };

    private readonly inputRef: React.RefObject<HTMLDivElement> = React.createRef();
    private eventsDataToDownload = new Array<string>();
    private eventsDataDownloading = new Set<string>();
    private interval?: NodeJS.Timeout;
    private mounted = false;

    componentDidMount() {
        this.mounted = true;
        const { event } = this.state;
        if (!event.id) {
            setTimeout(this.loadEvents);
            this.interval = setInterval(() => this.downloadEventsData(), 50);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    private loadEvents = async () => {
        const { workingUserId } = this.props;
        const hideProgress = showProgress('DuplicateEventDialog loadEvents');
        try {
            const events = (await Backend.getEntities<Event>(Backend.eventsQuery(workingUserId)))
                .filter(isEventNotDeleted)
                .sort((a, b) => b.date - a.date);
            if (!this.mounted) return;
            this.setState({ events });
            await this.loadEventsData(events.map(e => e.id));
        } finally {
            hideProgress()
        }
    }

    private downloadEventsData = () => {
        const { eventPortals } = this.state;
        const eventIds = this.eventsDataToDownload.splice(0, 20).filter(id => !eventPortals.has(id));
        if (eventIds.length === 0) {
            return;
        }
        eventIds.forEach(id => this.eventsDataDownloading.add(id));
        this.loadEventsData(eventIds);
    }

    private loadEventsData = async (eventIds: Array<string>) => {
        while (eventIds.length > 0) {
            const portionIds = eventIds.splice(0, 10);
            await this.loadEventsDataPortion(portionIds);
        }
    }

    private loadEventsDataPortion = async (eventIds: Array<string>) => {
        const { eventPortals } = this.state;
        const t0 = Date.now();
        const portals = await Backend.getEntities<Portal>(
            Backend.portalInfoDb,
            Backend.where(Backend.documentId(), 'in', eventIds)
        );
        if (!this.mounted) return;
        const t1 = Date.now();
        portals.forEach(portal => eventPortals.set(portal.id, portal));
        eventIds.forEach(id => this.eventsDataDownloading.delete(id));
        this.setState({ eventPortals });
        dbgLog(`loadEventsData events: ${eventIds.length}, t1: ${t1 - t0}`);
    }

    private handleClose = () => {
        const { showEvents } = this.state;
        if (showEvents) {
            this.setState({ showEvents: false });
            return;
        }
        this.props.handleClose();
    }

    private handleCloseNoBackdrop = (_e: string, reason: string) => {
        const { showEvents } = this.state;
        if (showEvents) {
            this.setState({ showEvents: false });
            return;
        }
        if ("backdropClick" === reason) {
            return;
        }
        this.handleClose();
    }

    private handleCreate = async () => {
        const { req } = this.state;
        if (!Backend.firebaseAuth.currentUser || !req.eventName.trim()) {
            return;
        }
        const duplicateRequest = { ...this.state.req };
        const hideProgress = showProgress('DuplicateEventDialog handleCreate');
        let addedId: string | undefined = undefined;
        try {
            await prepareRequest(duplicateRequest);
            const t1 = Date.now();
            const res = await axios.post(Urls.duplicateEvent, duplicateRequest);
            const t2 = Date.now();
            const responseData: EventDuplicateResponse = res.data;
            addedId = responseData.id
            dbgLog(`duplicated event result: ${responseData}, time spent ${t2 - t1}ms`);
        } finally {
            hideProgress()
        }
        this.props.handleClose(addedId);
    }

    private handleListEvents = (e: React.MouseEvent<any>) => {
        e.stopPropagation();
        const { showEvents } = this.state;
        this.setState({ showEvents: !showEvents });
    }

    private eventDedails = (event: Event) => {
        const { eventPortals } = this.state;
        let stepsTotal = -1;
        let stepsCompleted = -1;
        return {
            portal: eventPortals.get(event.id) ?? {} as Portal,
            portalLoaded: eventPortals.has(event.id),
            stepsCompleted,
            stepsTotal
        };
    }

    private selectEvent = (event: Event) => {
        const { req } = this.state;
        req.eventId = event.id;
        req.eventName = event.name + ' Copy';
        this.setState({ req, event, showEvents: false });
    }

    render() {
        const { event, page, req } = this.state;
        if (page === 1) {
            const { events, showEvents } = this.state;
            const endAdornment = (onClk: (e: React.MouseEvent<any>) => void) => <InputAdornment position="end">
                <IconButton tabIndex={-1} onClick={onClk} size="large">
                    {showEvents ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
            </InputAdornment>;
            return <XSMobileDialog open onClose={this.handleCloseNoBackdrop} maxWidth="sm" fullWidth={true}>
                <DialogAppBar label={<Flex>Duplicate event &nbsp; <ProBadge small /></Flex>} close={this.handleClose} />
                <DialogContent>
                    <FormControl variant="standard" margin="dense" fullWidth>
                        <TextField
                            fullWidth
                            variant="standard"
                            ref={this.inputRef}
                            label="Please, select event:"
                            value={event.name ?? ''}
                            placeholder="Select your event to duplicate"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                style: { width: '100%' },
                                autoComplete: 'off',
                                endAdornment: endAdornment(this.handleListEvents),
                                onClick: e => {
                                    if (!showEvents) {
                                        this.setState({ showEvents: true });
                                    } else {
                                        e.stopPropagation();
                                    }
                                }
                            }}
                        />
                    </FormControl>
                    <Popper
                        sx={{ zIndex: 1500 }}
                        placement='bottom-start'
                        open={showEvents && Boolean(this.inputRef.current)}
                        anchorEl={this.inputRef.current}>
                        <Paper style={{ maxWidth: 500, maxHeight: 400, overflow: 'auto', marginTop: 10 }}>
                            <ClickAwayListener onClickAway={() => this.setState({ showEvents: false })}>
                                <>
                                    {(events ?? []).map((ev, idx) => (
                                        <RowEventItem
                                            key={idx}
                                            event={ev}
                                            rounds={[]}
                                            {...this.eventDedails(ev)}
                                            handleItemClick={() => this.selectEvent(ev)}
                                        />
                                    ))}
                                </>
                            </ClickAwayListener>
                        </Paper>
                    </Popper>
                </DialogContent>
                <DialogActions>
                    <AppButton color="secondary" onClick={() => this.setState({ page: 2 })} disabled={!event.id}>Next</AppButton>
                </DialogActions>
            </XSMobileDialog>;
        }
        const { eventName, eventDate, copyGolfers, copySchedule, copyCompetitions } = req;
        const endAdornment = this.state ?
            <InputAdornment position="end">
                <BkCloseButton onClick={() => { req.eventName = ''; this.setState({ req }); }} />
            </InputAdornment> : null;
        const createDisabled = !eventName.trim();
        return <XSMobileDialog open onClose={this.handleClose} maxWidth="sm" fullWidth={true}>
            <DialogAppBar label={<Flex>Duplicate event &nbsp; <ProBadge small /></Flex>} close={this.handleClose} />
            <DialogContent>
                <FormControl variant="standard" margin="dense" fullWidth>
                    <TextField
                        autoFocus
                        variant="standard"
                        label="Event name"
                        value={eventName}
                        onKeyDown={e => processEnterKey(e, this.handleCreate)}
                        onChange={e => { req.eventName = e.target.value; this.setState({ req }); }}
                        InputProps={{ endAdornment }}
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '100%', maxWidth: NEW_EVENT_ELEM_WIDTH }}
                    />
                </FormControl>
                <Spacing />
                <MaterialDate
                    icon
                    enableUnderline
                    label={event.type === 'multiday' ? 'Event start date' : 'Event date'}
                    value={eventDate}
                    onChange={eventDate => { req.eventDate = eventDate.getTime(); this.setState({ req }); }}
                    format={EVENT_DATETIME_FORMAT_DLG}
                    popperPlacement={'bottom'}
                    style={{ width: '100%', maxWidth: NEW_EVENT_ELEM_WIDTH }}
                />
                <Spacing />
                <Grid container>
                    <Grid item xs={12} sm={8}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Keep from the previous event</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    style={{ margin: 2 }}
                                    label={<>Golfers &nbsp; <NoticeElement>A welcome email will be sent to golfers with an email address present.</NoticeElement></>}
                                    control={<Checkbox
                                        color="secondary"
                                        onChange={(e, v) => { e.stopPropagation(); req.copyGolfers = v; this.setState({ req }) }}
                                        checked={copyGolfers}
                                    />}
                                />
                                <FormControlLabel
                                    disabled={!copyGolfers}
                                    style={{ margin: 2 }}
                                    label={<>Teams & Schedule &nbsp; <NoticeElement>Golfers must be selected to copy previous team and schedule settings.</NoticeElement></>}
                                    control={<Checkbox
                                        color="secondary"
                                        onChange={(e, v) => { e.stopPropagation(); req.copySchedule = v; this.setState({ req }) }}
                                        checked={copySchedule}
                                    />}
                                />
                                <FormControlLabel
                                    style={{ margin: 2 }}
                                    label={'All competitions & Side Games'}
                                    control={<Checkbox
                                        color="secondary"
                                        onChange={(e, v) => { e.stopPropagation(); req.copyCompetitions = v; this.setState({ req }) }}
                                        checked={copyCompetitions}
                                    />}
                                />
                                <FormHelperText style={{ marginLeft: 24, marginTop: 0 }}>including payout settings</FormHelperText>
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div style={{ backgroundColor: AppColors.descriptionBkColor, padding: 8, marginTop: 8 }}>
                            <Typography style={{ fontSize: 12, fontWeight: 400 }}>
                                Course, start time, and event site (if customized) will be copied to your new event. You can adjust these and any other settings once the event is created.
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {!this.props.event.id &&
                    <AppButton color="info" onClick={() => this.setState({ page: 1 })} disabled={!event.id}>Back</AppButton>}
                <span style={{ flex: '1 1 0%' }} />
                <AppButton color="secondary" onClick={this.handleCreate} disabled={createDisabled}>Create event</AppButton>
            </DialogActions>
        </XSMobileDialog>;
    }
}

export default function(props: Omit<Props, 'workingUserId'>) {
    const userAware = useUserAware();
    if (!userAware.workingUserId) {
        return null;
    } else if (!userAware.hasPro) {
        const handleClosePorPlan = (name?: AccountActions) => {
            props.handleClose();
            accountAction(userAware, name);
        }
        return <ProPlanDialog handleClose={handleClosePorPlan} />;
    } else {
        return <DuplicateEventDialog workingUserId={userAware.workingUserId}  {...props} />;
    }
}

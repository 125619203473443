import * as React from 'react';
import { FormLabel, IconButton, FormControl, DialogActions, Radio, RadioGroup, FormControlLabel, Checkbox } from '@mui/material';
import { WithStyles } from '@mui/styles';
import CopyTextField from '../../../../common/components/CopyTextField';
import * as Backend from '../../../../util/firebase';
import {
    getRegistrationDate, Event, DEFAULT_REGISTRATION_EMAIL_NOTIFY, EventPaymentSettings, DEFAULT_EVENT_PAYMENT_SETTINGS_ENABLED, UserInfo
} from '../../../../types/EventTypes';
import { elog, tryUpdateUserPayPalInfo } from '../../../Event';
import { formatDateDashed1, isTrue } from '../../../../util/utility';
import { XSMobileDialog } from '../../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../../common/dialog/DialogAppBar';
import AppButton from '../../../../common/components/AppButton';
import MaterialDate from '../../../../common/MaterialDate';
import { Spacing, EditIcon, Flex } from '../../../../common/Misc';
import { styles, useAppStyles } from '../../../../styles';
import { Urls } from '../../../../util/config';
import { NoticeElement, showProgress } from '../../../../redux/ReduxConfig';
import { PayPalPaymentSettings } from "../../../../payments/paypal/PayPalPaymentSettings";
import { FirebaseUserDataComponent } from "../../../../common/WithData";
import * as Utils from "../../../../util/utility";
import { ParticipantsNumberField } from "../../../../common/components/ParticipantsNumberField";

type Props = {
    event: Event;
    onClose: VoidFunction;
} & WithStyles<typeof styles>;

type State = {
    registrationByAdmin?: boolean;
    registrationEmailNotify?: boolean;
    registrationDeadline: Date;
    registrationLink: String;
    paymentSettings: EventPaymentSettings;
    userInfo?: UserInfo;
    maxGolfers?: number;
};

class RegistrationSettingsDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            registrationByAdmin: props.event.registrationByAdmin,
            registrationEmailNotify: props.event.registrationEmailNotify,
            registrationDeadline: new Date(getRegistrationDate(props.event)),
            registrationLink: '',
            paymentSettings: props.event.paymentSettings ? {
                ...props.event.paymentSettings
            } : {
                enabled: DEFAULT_EVENT_PAYMENT_SETTINGS_ENABLED,
                platform: 'PayPal',
                feeCost: 0,
                payeeEmailAddress: '',
                currencyCode: ''
            },
            maxGolfers: this.props.event.maxGolfers
        }
    };

    private setPaymentSettingsEnableStatus = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
        event.stopPropagation();
        const { paymentSettings } = this.state;
        paymentSettings.enabled = value;
        this.setState({ paymentSettings });
    };

    private setFeeCostWithDescription = (newFeeCostStr: string, description?: string) => {
        const { paymentSettings } = this.state;
        paymentSettings.feeCost = parseFloat(newFeeCostStr) ?? 0;
        if (description) {
            paymentSettings.feeDescription = description;
        }
        this.setState({ paymentSettings });
    };

    private setPayeeEmail = (newEmail: string) => {
        const { paymentSettings } = this.state;
        paymentSettings.payeeEmailAddress = newEmail;
        this.setState({ paymentSettings });
    };

    private handleSave = async () => {
        const { event } = this.props;
        const {
            registrationByAdmin, registrationDeadline, registrationEmailNotify, paymentSettings, userInfo, maxGolfers
        } = this.state;
        const eventUpdate = { id: event.id, exists: true } as Event;
        eventUpdate.registrationByAdmin = registrationByAdmin || false;
        eventUpdate.registrationEmailNotify = registrationEmailNotify || false;
        eventUpdate.registrationDeadline = registrationDeadline.valueOf();
        eventUpdate.paymentSettings = paymentSettings;
        eventUpdate.maxGolfers = maxGolfers;
        await Backend.withTransaction(async transaction => {
            const hideProgress = showProgress('RegistrationSettingsDialog handleSave');
            try {
                Backend.updateInTransaction(Backend.eventsDb, eventUpdate, transaction);
                await tryUpdateUserPayPalInfo(userInfo, paymentSettings, transaction);
                elog(event, `Registration settings modified`, registrationByAdmin ? `Reg by admin` : `Reg by link till ${formatDateDashed1(registrationDeadline.valueOf())}`, `Id: ${event.id}`);
                this.handleClose();
                return Promise.resolve();
            } finally {
                hideProgress();
            }
        });
    };

    private handleClose = () => this.props.onClose();

    private datepickerRef: HTMLElement | null = null;

    openDatepicker = (e: React.MouseEvent) => {
        if (this.datepickerRef) {
            e.preventDefault();
            this.datepickerRef.click();
        }
    };

    NotifyLabel = () => {
        return (
            <span>
                Notify me via email when a new golfer registers
                <NoticeElement>
                    <span>
                        Receive an email confirmation when golfers self-register via the registration page.<br />
                        This action of golfers self-registering can also be found by clicking tools {'->'} changelog.
                    </span>
                </NoticeElement>
            </span>
        );
    };

    private userRemoteChanged = (userInfo: UserInfo) => {
        const currentPaymentSettings = this.state.paymentSettings;
        const eventPaymentSettings = this.props.event.paymentSettings;
        currentPaymentSettings.payeeEmailAddress = eventPaymentSettings?.payeeEmailAddress ?? userInfo.payPalEmailAddress ?? '';
        currentPaymentSettings.currencyCode = eventPaymentSettings?.currencyCode ?? userInfo.payPalCurrencyCode ?? '';
        this.setState({ paymentSettings: currentPaymentSettings, userInfo });
    };

    private updatePaymentSettings = (newPayPalEmailAddress: string, newPayPalCurrencyCode: string) => {
        const paymentSettings = this.state.paymentSettings;
        if (newPayPalEmailAddress) {
            paymentSettings.payeeEmailAddress = newPayPalEmailAddress.trim();
        }
        if (newPayPalCurrencyCode) {
            paymentSettings.currencyCode = newPayPalCurrencyCode.trim();
        }
        this.setState({ paymentSettings });
    };

    render() {
        const { classes, event } = this.props;
        const { registrationByAdmin, registrationDeadline, registrationEmailNotify, paymentSettings, maxGolfers } = this.state;
        const registrationLink = Urls.makeAboutLink(event.publicId);
        return <>
            <XSMobileDialog open fullWidth maxWidth="sm" onClose={this.handleClose}>
                <DialogAppBar label="Registration" close={this.handleClose} />
                <div style={{ padding: '16px' }}>
                    <FormControl
                        fullWidth
                        margin="dense"
                        variant="standard"
                        style={{ flexDirection: 'column' }}>
                        <FormLabel>Golfer registration preferences</FormLabel>
                        <RadioGroup value={registrationByAdmin ? '0' : '1'} onChange={e => this.setState({ registrationByAdmin: e.target.value === '0' })}>
                            <FormControlLabel
                                value={'0'}
                                className={classes.noLeftMargin}
                                control={<Radio color="primary" />}
                                label="Only event administrator can add golfers"
                            />
                            <FormControlLabel
                                value={'1'}
                                className={classes.noLeftMargin}
                                control={<Radio color="primary" />}
                                label="Open to anyone with the event link"
                            />
                        </RadioGroup>
                    </FormControl>
                    <Spacing />
                    <FormControl>
                        <ParticipantsNumberField
                            maxWidth={454}
                            disabled={false}
                            maxGolfers={maxGolfers}
                            onChange={(e, absMaxGolfers) => this.setState({ maxGolfers: Utils.parseInt(e.target.value, 1, absMaxGolfers) || undefined })}
                        />
                    </FormControl>
                    <Spacing />
                    <FormControl sx={{ visibility: !registrationByAdmin ? 'visible' : 'hidden' }}>
                        <CopyTextField color="info" fieldValue={registrationLink} btnLabel="Copy link" readOnly={true} />
                        <Spacing />
                        <FormLabel>Registration deadline﻿ - through</FormLabel>
                        <Flex>
                            <MaterialDate
                                maxDate={event.date}
                                value={registrationDeadline.valueOf()}
                                inputRef={ref => this.datepickerRef = ref}
                                onChange={date => this.setState({ registrationDeadline: date })}
                            />
                            <IconButton onClick={this.openDatepicker} size="large"><EditIcon /></IconButton>
                        </Flex>
                        <FormControlLabel
                            value={'1'}
                            className={classes.noLeftMargin}
                            control={<Checkbox color="secondary"
                                onChange={(e, v) => { e.stopPropagation(); this.setState({ registrationEmailNotify: v }); }}
                                checked={isTrue(registrationEmailNotify, DEFAULT_REGISTRATION_EMAIL_NOTIFY)}
                            />}
                            label={<this.NotifyLabel />}
                        />
                        <PayPalPaymentSettings
                            style={{ maxWidth: 454 }}
                            paymentSettings={paymentSettings}
                            setPayeeEmail={this.setPayeeEmail}
                            setPaymentSettingsEnableStatus={this.setPaymentSettingsEnableStatus}
                            onPayPalDialogSave={(emailAddress: string, currencyCode: string) => this.updatePaymentSettings(emailAddress, currencyCode)}
                            setFeeCostWithDescription={this.setFeeCostWithDescription}
                        />
                        {!paymentSettings.enabled && <Spacing />}
                    </FormControl>
                </div>
                <DialogActions>
                    <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                    <AppButton color="secondary" className={classes.iconButton} onClick={this.handleSave}>Save</AppButton>
                </DialogActions>
            </XSMobileDialog>
            <FirebaseUserDataComponent onData={this.userRemoteChanged} />
        </>;
    }
}

export default function(props: Omit<Props, 'userAware' | 'classes'>) {
    const classes = useAppStyles();
    return <RegistrationSettingsDialog classes={classes}   {...props} />;
}

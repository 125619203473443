import { Alert, Collapse } from '@mui/material';
import * as React from 'react';
import { TransitionGroup } from 'react-transition-group';
import RichTextView from 'src/common/form/richtext/RichTextView';
import { useCookies } from 'react-cookie';
import { AppColors } from './Theme';
import { Announcement } from 'src/types/EventTypes';

type Props = {
    announcement?: Announcement;
};

export default function AnnouncementAlert(props: Props) {
    const { announcement } = props;
    const [cookies, setCookie] = useCookies(['announcementShown']);
    const closeAnnouncement = () => {
        setCookie('announcementShown', announcement?.timestamp, { path: '/' });
    }
    const announcementShown = parseInt(cookies.announcementShown);
    const showAnnouncement = announcement?.text && announcementShown != announcement?.timestamp;
    return (
        <TransitionGroup>
            {showAnnouncement &&
                <Collapse>
                    <Alert
                        icon={false}
                        onClose={closeAnnouncement}
                        sx={{
                            position: 'relative',
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                            color: AppColors.white,
                            borderRadius: 0,
                            backgroundColor: AppColors.webSuccess
                        }}
                    >
                        <RichTextView value={announcement.text} style={{ minHeight: 0, padding: 0, width: '100%' }} />
                    </Alert>
                </Collapse>}
        </TransitionGroup>
    );
}

import * as React from 'react';
import { ButtonGroup, CircularProgress, Grid, Hidden, Typography } from '@mui/material';
import { useAppStyles } from 'src/styles';
import { AppColors } from 'src/main/Theme';
import { useUserAware } from './Auth';
import AppButton from 'src/common/components/AppButton';
import { Flex, FlexCenter, Spacing } from 'src/common/Misc';
import { BonusBand, BottomArrowIcon, DoneIcon, GiftIcon, GolfpadTagsIcon } from 'src/common/Icons';
import { FREE_OPTS, PRO_OPTS, PRO_OPTS_EXT } from 'src/util/strings';
import { SubscriptionInterval } from 'src/types/EventTypes';
import { AccountActions } from './StripeRedirect';
import { formatCurrency } from 'src/util/utility';
import { Emails, Urls } from 'src/util/config';
import { ManageSubscriptionDialog } from './ManageSubscriptionDialog';

const isWebKit = navigator.userAgent.indexOf('AppleWebKit') != -1;

export function getScrollPosition(element?: HTMLElement | null) {
    const position = element?.getBoundingClientRect()
    return { x: position?.left, y: position?.top };
}

function WhiteButton({ children, onClick }: { children: React.ReactNode, onClick: VoidFunction }) {
    return (
        <AppButton
            style={{ width: '100%', backgroundColor: AppColors.white, color: AppColors.webGreen300, border: `1px solid ${AppColors.webGreen300}`, fontWeight: 600 }}
            onClick={onClick}>
            {children}
        </AppButton>
    );
}

function GreenButton({ children, onClick }: { children: React.ReactNode, onClick: VoidFunction }) {
    return (
        <AppButton
            style={{ width: '100%', backgroundColor: AppColors.webGreen500 }}
            onClick={onClick}>
            {children}
        </AppButton>
    );
}

function OptsItem({ backgroundColor, icon, children }: { backgroundColor?: string; icon?: React.ReactNode, children: React.ReactNode }) {
    return (
        <Flex style={{ flexDirection: 'row', backgroundColor: backgroundColor }}>
            <span style={{ paddingRight: 4, alignSelf: 'flex-start' }}>
                <Flex>{icon}</Flex>
            </span>
            <span>
                <Flex>{children}</Flex>
            </span>
        </Flex>
    );
}

function OptsList({ green, opts }: { green?: boolean, opts: Array<string> }) {
    const classes = useAppStyles();
    return (
        opts.map((opt, idx) => <div key={idx}>
            <OptsItem icon={<DoneIcon
                className={classes.settingsPlanRoundIcon}
                sx={{ backgroundColor: green ? AppColors.webGreen300 : AppColors.webGrey200 }}
            />}>
                <Typography key={idx}>{opt}</Typography>
            </OptsItem>
            <Spacing height={4} />
        </div>)
    );
}

function YearlyBonus1() {
    const classes = useAppStyles();
    return (
        <div className={classes.settingsPlanBonus}>
            <img src={Urls.golfpadTagsImg} style={{ float: 'right', margin: 8, width: 80 }} />
            <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                Included with annual subscription:
            </Typography>
            <span>
                <OptsItem icon={<GolfpadTagsIcon className={classes.roundIcon}
                    sx={{ color: AppColors.white, backgroundColor: AppColors.webGreen300 }}
                />}>
                    <Typography sx={{ fontSize: '16px' }}>
                        One set of Golf Pad TAGS automatic tracking system
                    </Typography>
                </OptsItem>
                <OptsItem icon={<GiftIcon
                    className={classes.roundIcon}
                    sx={{ color: AppColors.white, backgroundColor: AppColors.webGreen300 }}
                />}>
                    <Typography sx={{ fontSize: '16px' }}>
                        Four Golf Pad Premium <br />
                        1-year upgrade codes
                    </Typography>
                </OptsItem>
            </span>
            <Typography sx={{ color: AppColors.webGrey400 }}>Over $200 of extra value!</Typography>
            <Typography>Perfect to share or use as prizes</Typography>
            <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: -110, right: -26, width: 120 }}>
                    <BonusBand />
                </div>
            </div>
        </div>
    );
}

function YearlyBonus2({ onClick }: { onClick: VoidFunction }) {
    const classes = useAppStyles();
    return (
        <div className={classes.settingsPlanBonus}>
            <Typography sx={{ fontSize: 18, fontWeight: 500, lineHeight: '150%' }}>
                Choose Annual Subscription to receive extra bonus
            </Typography>
            <FlexCenter style={{ fontSize: 20, fontWeight: 500, lineHeight: '150%' }}>
                <img src={Urls.golfpadTagsImg} style={{ float: 'left', margin: 8, width: 90 }} />
                <img src={Urls.giftImg} style={{ float: 'left', margin: 8, width: 160 }} />
            </FlexCenter>
            <OptsItem backgroundColor={AppColors.white} icon={<GiftIcon
                className={classes.roundIcon24}
                sx={{ color: AppColors.webBlue400, backgroundColor: AppColors.webBlue100 }}
            />}>
                <Typography sx={{ fontSize: '16px' }}>
                    Four Golf Pad Premium 1-year upgrade codes
                </Typography>
            </OptsItem>
            <Spacing height={8} />
            <OptsItem backgroundColor={AppColors.white} icon={<GolfpadTagsIcon
                className={classes.roundIcon24}
                sx={{ color: AppColors.webBlue400, backgroundColor: AppColors.webBlue100 }}
            />}>
                <Typography sx={{ fontSize: '16px' }}>
                    Golf Pad TAGS set (Includes 15 Tags)
                </Typography>
            </OptsItem>
            <Spacing height={8} />
            <GreenButton onClick={onClick}>Select Annual</GreenButton>
        </div>
    );
}

function ScrolledView({ fullMode, green, header, children }: { fullMode?: boolean, green?: boolean, header: React.ReactNode, children: React.ReactNode }) {
    const [scrollPos, setScrollPos] = React.useState(0);
    const classes = useAppStyles();
    const scroller = React.useRef<HTMLDivElement>(null);
    React.useLayoutEffect(() => {
        const freeElem = scroller.current;
        const handleScroll = () => {
            if (freeElem) {
                setScrollPos(freeElem.scrollTop);
            }
        }
        freeElem?.addEventListener('scroll', handleScroll);
        return () => {
            freeElem?.removeEventListener('scroll', handleScroll);
        };
    }, [scroller.current]);
    return <>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative' }}>
            <div style={{
                paddingLeft: 16,
                paddingRight: !fullMode && isWebKit ? 16 + 8 : 16
            }}>
                <div style={{
                    paddingLeft: 16,
                    paddingTop: 24,
                    paddingRight: 16,
                    paddingBottom: 16,
                    borderWidth: 1,
                    borderRadius: '16px 16px 0px 0px',
                    borderStyle: 'solid solid none solid',
                    backgroundColor: green ? AppColors.webGreen100 : AppColors.white,
                    borderColor: green ? AppColors.webGreen300 : AppColors.webGrey200,
                }}>
                    {header}
                </div>
            </div>
            <div className={classes.settingsPlanTabScroller} style={{ height: !fullMode ? 300 : '100%', overflowY: !fullMode ? 'auto' : 'hidden' }} ref={scroller}>
                {children}
            </div>
            {(!fullMode && scrollPos === 0) && <div style={{
                position: 'absolute',
                backgroundColor: AppColors.webGrey100,
                left: 16,
                right: isWebKit ? 16 + 8 : 16,
                bottom: 0,
            }}>
                <div
                    onClick={() => scroller.current?.scrollTo({ top: 1000 })}
                    style={{
                        fontSize: '13px',
                        fontWeight: 500,
                        cursor: 'pointer',
                        lineHeight: '150%',
                        border: '1px solid ' + (green ? AppColors.webGreen300 : AppColors.webGrey200),
                        borderStyle: 'none solid solid solid',
                        borderRadius: '0px 0px 16px 16px',
                        paddingTop: 4,
                        paddingBottom: 4,
                        paddingLeft: 16,
                        backgroundColor: green ? AppColors.webGreen100 : AppColors.white,
                        color: AppColors.webBlue500,
                    }}
                >
                    <Flex>
                        Read more <BottomArrowIcon animated sx={{ width: '16px' }} />
                    </Flex>
                </div>
            </div>}
        </div>
    </>;
}

export default function AccountPlans({ variant, action }: { variant: 'short' | 'long', action: (name?: AccountActions) => void }) {
    const [subscription, setSubscription] = React.useState<SubscriptionInterval>('year');
    const [viewMode, setViewMode] = React.useState<'pro' | 'free'>('pro');
    const [manageSubscription, setManageSubscription] = React.useState(false);
    const userAware = useUserAware();
    const classes = useAppStyles();
    const pro = userAware.hasPro;
    const freeOpts = FREE_OPTS;
    const proOpts = variant === 'long' ? PRO_OPTS.concat(PRO_OPTS_EXT) : PRO_OPTS;
    const prodYearly = userAware.products?.find(p => p.interval === 'year');
    const prodMonthly = userAware.products?.find(p => p.interval === 'month');
    const accountAction = subscription === 'year' ? 'upgrade_yearly' : subscription === 'month' ? 'upgrade_mnthly' : undefined;
    const priceLabel = subscription === 'year' && prodYearly ?
        <>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                {formatCurrency(prodYearly.currency, prodYearly.price / 100)}
                <span style={{ color: AppColors.webGrey400, fontWeight: 400 }}>/year</span>&nbsp;
                (only {formatCurrency(prodYearly.currency, prodYearly.price / 12 / 100)} a month)
            </Typography>
        </> : subscription === 'month' && prodMonthly ? <>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                {formatCurrency(prodMonthly.currency, prodMonthly.price / 100)}<span style={{ color: AppColors.webGrey400, fontWeight: 400 }}>/month</span>
            </Typography>
        </> : <>
            <Typography>
                <span style={{ color: AppColors.webGrey400, fontWeight: 400 }}><CircularProgress size={16} /> loading prices...</span>
            </Typography>
        </>;
    const savingPrice = prodMonthly && prodYearly ? ((prodMonthly.price - prodYearly.price / 12) / prodMonthly.price * 100).toFixed(0) + '%' : undefined;
    const monthlyYear = prodMonthly ? formatCurrency(prodMonthly.currency, prodMonthly.price * 12 / 100) : undefined;
    const payModeButtons = (right: boolean) => <span style={{ float: right ? 'right' : undefined }}>
        <ButtonGroup size="small">
            <AppButton
                className={subscription === 'year' ? classes.settingsPlanButtonSelected : classes.settingsPlanButton}
                onClick={() => setSubscription('year')}>
                Yearly
            </AppButton>
            <AppButton
                className={subscription === 'month' ? classes.settingsPlanButtonSelected : classes.settingsPlanButton}
                onClick={() => setSubscription('month')}>
                Monthly
            </AppButton>
        </ButtonGroup>
    </span>;
    const switchModeButtons = <>
        <ButtonGroup size="small">
            <AppButton
                className={viewMode === 'free' ? classes.settingsPlanSwitchButtonSelected : classes.settingsPlanSwitchButton}
                onClick={() => setViewMode('free')}>
                Free
            </AppButton>
            <AppButton
                className={viewMode === 'pro' ? classes.settingsPlanSwitchButtonSelected : classes.settingsPlanSwitchButton}
                onClick={() => setViewMode('pro')}>
                Pro
            </AppButton>
        </ButtonGroup>
    </>;
    const savingsTextForMonth = <>
        <span style={{ color: AppColors.webGreen300, fontWeight: 600 }}>Save {savingPrice}</span> on a yearly subscription
    </>;
    const savingsTextForYear = <>
        You're saving <span style={{ color: AppColors.webGreen300, fontWeight: 1000 }}>{savingPrice}</span> on the annual plan
    </>;
    const freePlanHeader = <>
        <Typography sx={{ fontSize: 20, fontWeight: 500, lineHeight: '150%' }}>Free plan</Typography>
        <Spacing height={4} />
        <Typography sx={{ fontSize: 16, fontWeight: 500, lineHeight: '150%' }}>Free</Typography>
        <Spacing />
        {!pro && <div>
            <Typography style={{ backgroundColor: AppColors.webGrey150, textAlign: 'center', padding: 6, borderRadius: 20 }}>
                You are on the Free Plan
            </Typography>
        </div>}
    </>;
    const proPlanHeader = (includePayButtons: boolean) => <>
        {savingPrice && variant === 'short' && subscription === 'year' &&
            <Hidden mdDown>
                <div style={{ position: 'absolute', top: 0, textAlign: 'center', width: 'calc(100% - 54px)' }}>
                    <span style={{
                        backgroundColor: 'white',
                        padding: '0px 10px',
                        border: '1px solid ' + (AppColors.webBlue300),
                        borderStyle: 'none solid solid solid',
                        borderRadius: '0px 0px 6px 6px',
                    }}>
                        <s>{monthlyYear}</s> | {savingPrice} savings
                    </span>
                </div>
            </Hidden>}
        {includePayButtons && payModeButtons(true)}
        <Typography sx={{ fontSize: 20, fontWeight: 500, lineHeight: '150%' }} component={'span'}>Events Pro</Typography>
        <Spacing height={4} />
        {priceLabel}
        <Spacing />
        {pro ? <>
            <Typography style={{ backgroundColor: AppColors.webGreen300, color: AppColors.white, fontWeight: 600, textAlign: 'center', padding: 6, borderRadius: 20 }}>
                You are on the Pro Plan
            </Typography>
            <WhiteButton onClick={() => setManageSubscription(true)}>
                Manage subscription
            </WhiteButton>
        </> : <>
            <GreenButton onClick={() => action(accountAction)}>
                Upgrade
            </GreenButton>
        </>}
    </>;
    const freeOpsList = (
        <div className={classes.settingsPlanFreeList}>
            <OptsList opts={freeOpts} />
        </div>
    );
    const proOptsList = <>
        <div className={classes.settingsPlanProList}>
            <OptsList green opts={proOpts} />
        </div>
        {subscription === 'year' ? <div className={classes.settingsPlanBonusVstavka1}>
            <div className={classes.settingsPlanBonusVstavka2} />
        </div> : <div className={classes.settingsPlanBonusVstavka3}>
            <div className={classes.settingsPlanBonusVstavka2} />
            <Spacing height={8} />
            <div className={classes.settingsPlanBonusVstavka4} />
        </div>}
        <div>
            {subscription === 'year' ? <YearlyBonus1 /> : <YearlyBonus2 onClick={() => setSubscription('year')} />}
        </div>
    </>;
    return (
        <div style={{ width: '100%' }}>
            <div style={{ backgroundColor: AppColors.webGrey100 }} >
                <FlexCenter>
                    <Typography sx={{ paddingTop: '16px', fontSize: 24, fontWeight: 500, maxWidth: variant === 'short' ? 430 : undefined }}>
                        Golf Pad Events Pro – <br />
                        Manage with Confidence!
                    </Typography>
                </FlexCenter>
                {variant === 'long' && <div style={{ paddingLeft: 24 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 400, color: AppColors.webGrey400 }}>
                        Use Events Pro to organize great events on a professional level
                    </Typography>
                    <Spacing />
                    {savingPrice ?
                        <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                            {subscription === 'year' ? savingsTextForYear : savingsTextForMonth}
                        </Typography> :
                        <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                            &nbsp;
                        </Typography>}
                    <Spacing height={4} />
                </div>}
                <Hidden mdDown>
                    {variant === 'short' &&
                        <div style={{ width: '100%', textAlign: 'right' }}>
                            <span style={{ padding: 24 + (isWebKit ? 8 : 0) }}>
                                {payModeButtons(false)}
                            </span>
                        </div>}
                    <div style={{ width: '100%' }}>
                        <Grid container className={classes.settingsPlanRoot}>
                            <Grid item sm={12} md={6}>
                                <div className={classes.settingsPlanTabContent}>
                                    <ScrolledView fullMode={variant === 'long'} header={freePlanHeader}>
                                        {freeOpsList}
                                    </ScrolledView>
                                </div>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <div className={classes.settingsPlanTabContent} style={{ marginLeft: 0 }}>
                                    <ScrolledView fullMode={variant === 'long'} green header={proPlanHeader(variant === 'long')}>
                                        {proOptsList}
                                    </ScrolledView>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Hidden>
                <Hidden mdUp>
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        <span style={{ padding: 24 }}>
                            {switchModeButtons}
                        </span>
                    </div>
                    <Spacing />
                    <div style={{ width: '100%' }}>
                        {viewMode === 'free' ? <ScrolledView fullMode header={freePlanHeader}>
                            {freeOpsList}
                        </ScrolledView> : <ScrolledView fullMode green header={proPlanHeader(true)}>
                            {proOptsList}
                        </ScrolledView>}
                    </div>
                </Hidden>
                {variant === 'long' && <>
                    <FlexCenter>
                        <Typography sx={{ fontSize: 14, fontWeight: 400, maxWidth: 430 }}>
                            Still have questions? Contact us and we will answer about how you can use Events Pro to your advantage
                        </Typography>
                    </FlexCenter>
                    <Spacing />
                    <FlexCenter>
                        <a href={`mailto:${Emails.supportEmail}`}>
                            <AppButton color="inherit">
                                Contact us
                            </AppButton>
                        </a>
                    </FlexCenter>
                </>}
                <Spacing />
                <Spacing />
                <Spacing />
            </div>
            {manageSubscription &&
                <ManageSubscriptionDialog
                    userAware={userAware}
                    onClose={() => setManageSubscription(false)}
                />}
        </div>
    );
}

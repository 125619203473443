import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { AppColors } from "../../main/Theme";
import { InfoIconTooltip } from "./InfoToolTip";
import * as React from "react";
import { useAppStyles } from "../../styles";

type LabeledCheckboxWithToolTipControlProps = {
    label: string;
    tooltip: string;
    disabled?: boolean;
    checked?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const CheckboxWithToolTipControl = (props: LabeledCheckboxWithToolTipControlProps) => {
    const { label, tooltip, checked, disabled, onChange } = props;
    const classes = useAppStyles();
    const toolTipText = (
        <span>
            {tooltip}
        </span>
    );
    return (
        <FormControlLabel
            style={{ margin: 2 }}
            label={<Typography style={{ color: disabled ? AppColors.webBlack : AppColors.webGrey300 }}
                className={classes.basicLabel}>
                {label}
                <InfoIconTooltip
                    offset1={-7}
                    style={{ marginLeft: 6 }}
                    title={toolTipText}
                    placement={'bottom-start'}
                    leaveTouchDelay={4000}
                />
            </Typography>}
            control={<Checkbox
                color="secondary"
                disabled={disabled}
                onChange={onChange}
                checked={checked}
            />}
        />
    );
};
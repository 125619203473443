import * as React from 'react';
import { Event, getCourseName, formatTeesName, HolesType, Round } from '../../../../../types/EventTypes';
import LabeledField from '../../../../../common/form/LabeledField';
import { Flex, ListElem } from '../../../../../common/Misc';
import { deleteEventRound, eventDateTeeTime, formatDateUniversal, saveEventRound } from 'src/event/Event';
import { MONTH_DATE_FORMAT } from 'src/util/config';
import { RoundEditDialog } from 'src/event/list/RoundEditDialog';
import { withProgress } from 'src/util/ProgressPromise';
import { DateRangeIcon } from '@mui/x-date-pickers';

type Props = {
    event: Event;
    round: Round;
    rounds: Array<Round>;
};

interface State {
    courseDialogOpened?: boolean;
    holesType?: HolesType;
}

class RoundSelectionField extends React.Component<Props, State> {
    state: State = {
    };

    private deleteRound = async (round: Round) => {
        const { event, rounds } = this.props;
        await withProgress(deleteEventRound(event, round, rounds));
        this.setState({ courseDialogOpened: false });
    }

    private saveRound = async (round: Round, dates: Array<number>) => {
        const { event, rounds } = this.props;
        await withProgress(saveEventRound(event, round, rounds, dates));
        this.setState({ courseDialogOpened: false });
    }

    render() {
        const { round, rounds } = this.props;
        const { courseDialogOpened } = this.state;
        const prevRound = rounds.find(r => r.roundOrder === round.roundOrder - 1);
        const courseName = getCourseName(round.course);
        const label = `Round ${round.roundOrder}`;
        const sublabel = <Flex>
            <DateRangeIcon sx={{ width: '16px', marginRight: '2px' }} />
            {formatDateUniversal(eventDateTeeTime(round), MONTH_DATE_FORMAT)}
        </Flex>;
        const title = courseName || 'Not selected';
        const subtitle = formatTeesName(round);
        return <>
            <LabeledField
                label={<ListElem title={label} subtitle={sublabel} boldTitle />}
                value={<ListElem title={title} subtitle={subtitle} color={!!courseName ? 'inherit' : 'error'} />}
                edit={() => this.setState({ courseDialogOpened: true })}
            />
            {courseDialogOpened && <RoundEditDialog
                round={round}
                rounds={rounds}
                prevRound={prevRound}
                deleteRound={this.deleteRound}
                saveRound={this.saveRound}
                close={() => this.setState({ courseDialogOpened: false })}
            />}
        </>;
    }
}

export default RoundSelectionField;

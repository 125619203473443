import * as React from 'react';
import { EventData } from '../../../types/EventTypes';
import PrintTab from './PrintTab';
import ChangelogTab from './ChangelogTab';
import LinkTabs, { LinkTab } from '../common/LinkTabs';

type TabType = 'print' | 'changelog';

interface Props {
    eventData: EventData;
    initial?: string;
    onSelectedTab: (subtab: string) => void;
}

function ToolsTab(props: Props) {
    const { event } = props.eventData;
    const tabs: Array<LinkTab<TabType>> = [
        {
            id: 'print',
            label: 'PRINT',
            node: <PrintTab eventData={props.eventData} />
        },
        {
            id: 'changelog',
            label: 'CHANGELOG',
            node: <ChangelogTab event={event} />
        },
    ];
    return (
        <LinkTabs
            tabs={tabs}
            initial={props.initial || 'print'}
            parentUrl={`/events/${event.id}/tools`}
            onSelectedTab={props.onSelectedTab}
        />
    );
}

export default ToolsTab;

import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import TypedFormRadioLabel from '../../../common/form/TypedFormRadioLabel';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { range } from '../../../util/utility';
import { processEnterKey } from '../../../util/react_utils';
import FormControl from '@mui/material/FormControl';
import AppButton from '../../../common/components/AppButton';
import { Edit } from './CompetitionPayoutSettingsDialog';

type Props = {
    schedule: number[];
    handleSave: (schedule: number[]) => void;
} & WithStyles<typeof styles> & DialogProps;

type State = {
    schedule?: number[];
    current: number;
};

function findMatrixRowIndex(matrix: number[][], row: number[]) {
    const rowstr = JSON.stringify(row);
    return matrix.findIndex(s => JSON.stringify(s) === rowstr);
}

class CompetitionPayoutCommonSplitsDialog extends React.Component<Props, State> {
    private schedules: number[][] = [
        [],
        [100, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [50, 30, 20, 0, 0, 0, 0, 0, 0, 0],
        [42, 27, 14, 10, 7, 0, 0, 0, 0, 0],
        [40, 25, 12, 8, 7, 5, 3, 0, 0, 0],
        [35, 20, 10, 8, 6, 5, 5, 5, 4, 2],
        [25, 15, 8, 7, 6, 6, 5, 5, 5, 4, 4, 3, 3, 2, 2, 0, 0, 0, 0, 0],
        [20, 10, 8, 7, 6, 6, 5, 5, 4, 4, 4, 4, 3, 3, 3, 2, 2, 2, 1, 1]];

    state: State = {
        current: 0,
    };

    private handleClose = () => {
        const close = this.props.onClose;
        if (close) {
            close({} as React.SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    private handleSave = () => {
        const { schedule } = this.state;
        const { handleSave } = this.props;
        handleSave(schedule || this.props.schedule);
    }

    private onSplitChange = (current: number) => {
        this.setState({ current, schedule: this.schedules[current] });
    }

    render() {
        const { classes } = this.props;
        const schedule = this.state.schedule || this.props.schedule;
        const current = "" + findMatrixRowIndex(this.schedules, schedule);
        const title = 'Common Splits';
        return (
            <React.Fragment>
                <XSMobileDialog open={this.props.open} onClose={this.handleClose}>
                    <DialogAppBar label={title} close={this.handleClose} />
                    <DialogContent onKeyDown={uiEvent => processEnterKey(uiEvent, this.handleSave)} >
                        <Typography variant="body1">{'Choose from a common split'}</Typography>
                        <FormControl variant="standard">
                            <TypedFormRadioLabel key='default' currentValue={current} value={'1'}
                                handleChange={() => this.onSplitChange(1)} label={'1 payout, winner takes all.'} className={classes.radio} />
                        </FormControl><br />
                        <FormControl variant="standard">
                            <TypedFormRadioLabel key='default' currentValue={current} value={'2'}
                                handleChange={() => this.onSplitChange(2)} label={'3 payouts'} className={classes.radio} />
                            <div style={{ width: 560 }}>
                                <div className={classes.tableHeader}>
                                    {range(1, 4).map(i => <span className={classes.headerCell} key={i}>{i + (i === 1 ? 'st' : i === 2 ? 'nd' : i === 3 ? 'rd' : 'th')}</span>)}
                                </div>
                                <div className={classes.row}>
                                    {range(0, 3).map(i => <Edit disable value={this.schedules[2][i]} onValue={_ => { }} readonly key={i} />)}
                                </div>
                            </div>
                        </FormControl><br />
                        <FormControl variant="standard">
                            <TypedFormRadioLabel key='default' currentValue={current} value={'3'}
                                handleChange={() => this.onSplitChange(3)} label={'5 payouts'} className={classes.radio} />
                            <div style={{ width: 560 }}>
                                <div className={classes.tableHeader}>
                                    {range(1, 6).map(i => <span className={classes.headerCell} key={i}>{i + (i === 1 ? 'st' : i === 2 ? 'nd' : i === 3 ? 'rd' : 'th')}</span>)}
                                </div>
                                <div className={classes.row}>
                                    {range(0, 5).map(i => <Edit disable value={this.schedules[3][i]} onValue={_ => { }} readonly key={i} />)}
                                </div>
                            </div>
                        </FormControl><br />
                        <FormControl variant="standard">
                            <TypedFormRadioLabel key='default' currentValue={current} value={'4'}
                                handleChange={() => this.onSplitChange(4)} label={'7 payouts'} className={classes.radio} />
                            <div style={{ width: 560 }}>
                                <div className={classes.tableHeader}>
                                    {range(1, 8).map(i => <span className={classes.headerCell} key={i}>{i + (i === 1 ? 'st' : i === 2 ? 'nd' : i === 3 ? 'rd' : 'th')}</span>)}
                                </div>
                                <div className={classes.row}>
                                    {range(0, 7).map(i => <Edit disable value={this.schedules[4][i]} onValue={_ => { }} readonly key={i} />)}
                                </div>
                            </div>
                        </FormControl><br />
                        <FormControl variant="standard">
                            <TypedFormRadioLabel key='default' currentValue={current} value={'5'}
                                handleChange={() => this.onSplitChange(5)} label={'10 payouts'} className={classes.radio} />
                            <div style={{ width: 560 }}>
                                <div className={classes.tableHeader}>
                                    {range(1, 11).map(i => <span className={classes.headerCell} key={i}>{i + (i === 1 ? 'st' : i === 2 ? 'nd' : i === 3 ? 'rd' : 'th')}</span>)}
                                </div>
                                <div className={classes.row}>
                                    {range(0, 10).map(i => <Edit disable value={this.schedules[5][i]} onValue={_ => { }} readonly key={i} />)}
                                </div>
                            </div>
                        </FormControl><br />
                        <FormControl variant="standard">
                            <TypedFormRadioLabel key='default' currentValue={current} value={'6'}
                                handleChange={() => this.onSplitChange(6)} label={'15 payouts'} className={classes.radio} />
                            <div style={{ width: 560 }}>
                                <div className={classes.tableHeader}>
                                    {range(1, 11).map(i => <span className={classes.headerCell} key={i}>{i + (i === 1 ? 'st' : i === 2 ? 'nd' : i === 3 ? 'rd' : 'th')}</span>)}
                                </div>
                                <div className={classes.row}>
                                    {range(0, 10).map(i => <Edit disable value={this.schedules[6][i]} onValue={_ => { }} readonly key={i} />)}
                                </div>
                            </div>
                            <div style={{ marginTop: 5, width: 560 }}>
                                <div className={classes.tableHeader}>
                                    {range(1, 6).map(i => <span className={classes.headerCell} key={i}>{(10 + i) + (i === 1 ? 'st' : i === 2 ? 'nd' : i === 3 ? 'rd' : 'th')}</span>)}
                                </div>
                                <div className={classes.row}>
                                    {range(0, 5).map(i => <Edit disable value={this.schedules[6][10 + i]} onValue={_ => { }} readonly key={i} />)}
                                </div>
                            </div>
                        </FormControl><br />
                        <FormControl variant="standard">
                            <TypedFormRadioLabel key='default' currentValue={current} value={'7'}
                                handleChange={() => this.onSplitChange(7)} label={'20 payouts'} className={classes.radio} />
                            <div style={{ width: 560 }}>
                                <div className={classes.tableHeader}>
                                    {range(1, 11).map(i => <span className={classes.headerCell} key={i}>{i + (i === 1 ? 'st' : i === 2 ? 'nd' : i === 3 ? 'rd' : 'th')}</span>)}
                                </div>
                                <div className={classes.row}>
                                    {range(0, 10).map(i => <Edit disable value={this.schedules[7][i]} onValue={_ => { }} readonly key={i} />)}
                                </div>
                            </div>
                            <div style={{ marginTop: 5, width: 560 }}>
                                <div className={classes.tableHeader}>
                                    {range(1, 11).map(i => <span className={classes.headerCell} key={i}>{(10 + i) + 'th'}</span>)}
                                </div>
                                <div className={classes.row}>
                                    {range(0, 10).map(i => <Edit disable value={this.schedules[7][10 + i]} onValue={_ => { }} readonly key={i} />)}
                                </div>
                            </div>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                        <AppButton color="secondary" onClick={this.handleSave} disabled={!current}>Save</AppButton>
                    </DialogActions>
                </XSMobileDialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CompetitionPayoutCommonSplitsDialog);

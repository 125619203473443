import { showError, showProgress } from '../redux/ReduxConfig';

export function withProgress<T>(promise: Promise<T>, error?: string | boolean, ok?: string) {
    const hideProgress = showProgress(`withProgress error=${error} ok=${ok}`);
    return promise.then((value: T) => {
        hideProgress();
        if (ok) {
            showError(ok);
        }
        return Promise.resolve(value);
    }, (reason: any) => {
        hideProgress();
        if (error) {
            if (error === true) {
                showError(reason.message ? ' (reason: ' + reason.message + ')' : reason);
            } else {
                showError(error + (reason.message ? ' (reason: ' + reason.message + ')' : ''));
            }
        }
        return Promise.reject(reason);
    });
}

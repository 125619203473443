import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'typeface-poppins';
import 'typeface-roboto';
import './index.css';
import App from './main/App';

const splash = document.getElementById('splash_elem');
if (splash) {
    setTimeout(() => splash.classList.add('hidden'));
}

//<React.StrictMode>
//</React.StrictMode>

const root = createRoot(document.getElementById('root')!);
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRules } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';

const styles = (theme: Theme) => {
    return {
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            paddingBottom: theme.spacing(1),
            '& > *': {
                marginRight: theme.spacing(1),
                marginTop: theme.spacing(1),
                flexShrink: 0
            }
        },
        mrg: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        }
    } as StyleRules;
};

interface ButtonBarProps {
    margin?: boolean;
    style?: React.CSSProperties;
    children: Array<React.ReactNode> | React.ReactNode;
}

function ButtonBar({ classes, children, margin, style }: ButtonBarProps & WithStyles<typeof styles>) {
    return (
        <div className={`${classes.root} ${margin ? classes.mrg : ''}`} style={style}>
            {children}
        </div>
    );
}

export default withStyles(styles)(ButtonBar);

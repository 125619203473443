import * as React from 'react';
import AvatarEditor from 'react-avatar-editor';
import { DialogContent, IconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRules } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ChangeEvent } from 'react';
import { showProgress } from '../../redux/ReduxConfig';
import { XSMobileDialog } from '../dialog/MobileDialog';
import AppButton from '../../common/components/AppButton';
import * as Backend from '../../util/firebase';
import CloseIcon from "@mui/icons-material/Close";

const styles = (theme: Theme) => {
    return {
        root: {
            alignSelf: 'center',
            padding: theme.spacing(1)
        },
        actions: {
            justifyContent: 'center'
        },
        zoom: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: theme.spacing(0.5),
            width: 312
        }
    } as StyleRules;
};

interface State {
    scale: number;
    file?: File;
}

type Props = {
    file: File;
    setImage: (url: string) => void;
    close: () => void;
    height: number;
    width: number;
    storageRef: Backend.StorageReference;
    maxWidth: 'xs' | 'sm';
    rounded: boolean;
} & WithStyles<typeof styles>;

class ImageCropDialog extends React.Component<Props, State> {
    private editor?: any;

    state: State = {
        scale: 1
    };

    private getImageScaledToCanvas() {
        const editor = this.editor;
        if (!editor) {
            return;
        }
        const { width, height } = editor.getDimensions();
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        if (this.editor.isVertical()) {
            canvas.width = height;
            canvas.height = width;
        } else {
            canvas.width = width;
            canvas.height = height;
        }
        const context = canvas.getContext('2d')!;
        editor.paintImage(context, editor.state.image, 0, 1);
        context.save();
        context.fillStyle = 'rgba(255, 255, 255, 0.0)';
        context.globalCompositeOperation = 'destination-over';
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.restore();
        return canvas;
    }

    private onClickSave = () => {
        const { close, storageRef, setImage, file } = this.props;
        if (!this.editor) {
            return;
        }
        close();
        const contentType = file?.type === 'image/png' ? file.type : 'image/jpeg';
        const contentExt = file?.type === 'image/png' ? '.png' : '.jpg';
        const fileName = Date.now().toString() + contentExt;
        const hideProgress = showProgress('getImageScaledToCanvas');
        const canvas = this.getImageScaledToCanvas()!;
        let callback = async (result: Blob | any) => {
            if (!result) {
                hideProgress('Unexpected error');
                return;
            }
            try {
                const metadata = { contentType };
                const uploadResult = await Backend.uploadBytes(Backend.ref(storageRef, fileName), result, metadata);
                const downloadURL = await Backend.getDownloadURL(uploadResult.ref);
                setImage(downloadURL);
                hideProgress();
            } catch (err: any) {
                hideProgress('Image upload failed: ' + err.message)
            }
        };
        canvas.toBlob(callback, contentType, 90);
    }

    private handleClose = () => {
        this.props.close();
    }

    private handleScale = (e: ChangeEvent<HTMLInputElement>) => {
        const scale = parseFloat(e.target.value);
        this.setState({ scale });
    }

    render() {
        const { maxWidth, file, width, height, rounded, classes } = this.props;
        const { scale } = this.state;
        const dialogWidth = maxWidth === 'xs' ? 360 : 600;
        let canvasWidth = width;
        let canvasHeight = height;
        if (width > dialogWidth - 48) {
            canvasWidth = dialogWidth - 48;
            canvasHeight = canvasWidth / width * height;
        }
        return (
            <XSMobileDialog
                open
                fullWidth
                maxWidth={maxWidth}
                onClose={this.handleClose}
                PaperProps={{ sx: { width: 'calc(100% - 36px)', maxWidth: maxWidth === 'xs' ? 424 : undefined } }}
            >
                <div className={classes.root}>
                    <DialogContent>
                        <AvatarEditor
                            border={0}
                            image={file}
                            width={width}
                            height={height}
                            scale={this.state.scale}
                            borderRadius={rounded ? width / 2 : 0}
                            ref={editor => this.editor = editor}
                            style={{ width: canvasWidth, height: canvasHeight }}
                        />
                        <IconButton sx={{ position: 'absolute', right: 4, top: 4 }} onClick={this.handleClose} color="secondary" size="small">
                            <CloseIcon />
                        </IconButton>
                        <br />
                        <div className={classes.zoom}>
                            Zoom:&nbsp;<input type="range" onChange={this.handleScale} min="0.5" max="3" step="0.01" value={scale} />
                            <AppButton sx={{ marginLeft: 5 }} color="secondary" onClick={this.onClickSave}>Save</AppButton>
                        </div>
                    </DialogContent>
                </div>
            </XSMobileDialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ImageCropDialog);

import * as React from 'react';
import * as Backend from '../util/firebase';
import { hideAllProgresses } from '../redux/ReduxConfig';
import { dbgLog, getServerErrorMessage, logInfo } from 'src/util/utility';
import { useNavigate } from 'react-router-dom';
import { Urls } from 'src/util/config';
import CryptoJS from 'crypto-js';
import { AccessData, Announcement, MAX_GOLFERS, MAX_GOLFERS_PRO, Product, hasProAccess } from 'src/types/EventTypes';
import axios from 'axios';
import { prepareRequest } from 'src/event/Event';

const UTF8 = CryptoJS.enc.Utf8;
const HEX = CryptoJS.enc.Hex;

export type LoginStatus = 'None' | 'Logging' | 'Logged';

export interface UserAware {
    user?: Backend.User;
    loginStatus: LoginStatus;
    hasSup?: boolean;
    hasPro?: boolean;
    effectivePro?: boolean;
    eventId?: string;
    workingUserId?: string;
    effectiveUserId?: string;
    signingOut?: boolean;
    hasUpdated?: boolean;
    absMaxGolfers: number;
    grant: number;
    products: Array<Product> | undefined;
    announcement?: Announcement; 
    setAnnouncement: (announcement?: Announcement) => void;
    setEffectiveUserId: (effectiveUserId?: string) => void;
    setSigningOut: (signingOut: boolean) => void;
    setHasUpdated: (hasUpdated: boolean) => void;
    setEventId: (eventId: string | undefined) => void;
    setEffectivePro: (val: boolean) => void;
}

export interface WithUserAware {
    userAware: UserAware;
}

export interface WithPossibleUserAware {
    userAware?: UserAware;
}

const UlogIn = () => Urls.logIn + '/' + UTF8.parse(window.location.origin + '/sso').toString(HEX);
const UsignUp = () => Urls.signUp + '/' + UTF8.parse(window.location.origin + '/sso').toString(HEX);
const UlogOut = () => Urls.logOut + '/' + UTF8.parse(window.location.origin + '/login').toString(HEX);

export function remoteLogout() {
    Backend.trackEvent('user_logout');
    window.location.assign(UlogOut());
}

export function remoteLogin() {
    hideAllProgresses();
    Backend.trackEvent('user_sign_in_start');
    window.location.assign(UlogIn());
}

export function remoteSignup() {
    hideAllProgresses();
    Backend.trackEvent('user_sign_up_start');
    window.location.assign(UsignUp());
}

export function RemoteLogin() {
    const navigate = useNavigate();
    Backend.trackEvent('user_sign_in_start');
    React.useEffect(() => {
        navigate(UlogIn());
    });
    return null;
}

export function RemoteSignup() {
    const navigate = useNavigate();
    Backend.trackEvent('user_sign_up_start');
    React.useEffect(() => {
        navigate(UsignUp());
    });
    return null;
}

export const UserAwareContext = React.createContext<UserAware>(null!);

export function UserAwareProvider({ children }: { children: React.ReactNode }) {
    const [loginStatus, setLoginStatus] = React.useState<LoginStatus>('Logging');
    const [hasSup, setSup] = React.useState(false);
    const [hasPro, setPro] = React.useState(false);
    const [effectiveUserId, setEffectiveUserId] = React.useState<string | undefined>();
    const [signingOut, setSigningOut] = React.useState(false);
    const [hasUpdated, setHasUpdated] = React.useState(false);
    const [eventId, setEventId] = React.useState<string | undefined>();
    const [user, setUser] = React.useState<Backend.User | undefined>();
    const [effectivePro, setEffectivePro] = React.useState(false);
    const [absMaxGolfers, setAbsMaxGolfers] = React.useState(MAX_GOLFERS);
    const [products, setProducts] = React.useState<Array<Product> | undefined>(undefined);
    const [grant, setGrant] = React.useState(0);
    const [announcement, setAnnouncement] = React.useState<Announcement | undefined>()
    React.useEffect(() => {
        const controller = new AbortController();
        let userUnsubscribe: VoidFunction | undefined = undefined;
        async function loadProductsInfo() {
            if (products) {
                return;
            }
            setProducts([]);
            try {
                const request = await prepareRequest({ token: '', action: '', source: '' });
                setProducts((await axios.post(Urls.listProducts, request, { signal: controller.signal })).data.products);
            } catch (err) {
                dbgLog('Failed to load product prices info: ' + getServerErrorMessage(err));
            }
        }
        const authUnsubscribe = Backend.firebaseAuth.onAuthStateChanged(user => {
            logInfo(`u ${user?.uid}...`);
            if (!user) {
                if (userUnsubscribe) {
                    userUnsubscribe();
                    userUnsubscribe = undefined;
                }
                setSup(false);
                setPro(false);
                setAbsMaxGolfers(MAX_GOLFERS);
                setEffectivePro(false);
                setLoginStatus('None');
                setUser(undefined);
                return;
            }
            userUnsubscribe = Backend.onSnapshot(Backend.doc(Backend.accessDb, user.uid), doc => {
                const accessData = doc.data() as AccessData;
                const exp = accessData?.exp ?? 0;
                const grant = accessData?.grant ?? 0;
                const pro = hasProAccess(accessData);
                const sup = accessData?.sup ?? false;
                logInfo(`u ${doc.id} ${sup} ${pro} (${grant}/${exp})`);
                setSup(sup);
                setPro(pro);
                setGrant(grant);
                setAbsMaxGolfers(pro ? MAX_GOLFERS_PRO : MAX_GOLFERS);
                setEffectivePro(pro);
                setLoginStatus('Logged');
                setUser(user);
                loadProductsInfo();
            });
        });
        return () => {
            controller.abort();
            if (userUnsubscribe) {
                userUnsubscribe();
                userUnsubscribe = undefined;
            }
            if (authUnsubscribe) {
                authUnsubscribe();
            }
        }
    }, []);
    const value = {
        user, loginStatus,
        absMaxGolfers,
        hasSup, hasPro,
        effectiveUserId, setEffectiveUserId,
        signingOut, setSigningOut,
        hasUpdated, setHasUpdated,
        eventId, setEventId,
        effectivePro, setEffectivePro,
        announcement, setAnnouncement,
        products, grant,
        workingUserId: effectiveUserId ?? user?.uid
    };
    return (
        <UserAwareContext.Provider value={value}>
            {children}
        </UserAwareContext.Provider>
    );
}

export function useUserAware() {
    return React.useContext(UserAwareContext);
}

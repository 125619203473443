import { GHINGolfer } from "../types/EventTypes";
import { DAY_MILLIS, dbgLog, makeParameterizedGetRequestUrl } from "../util/utility";
import { Urls } from "../util/config";
import axios from "axios";
import { defaultAxiosRequestConfig } from "../util/axios_utils";
import * as Backend from "../util/firebase";

export async function requestGhinGolferData(handicapIds: Set<string>) {
    const idsArray = Array.from(handicapIds);
    dbgLog(`requestGhinGolferData for ${JSON.stringify(idsArray)}`);
    const url = makeParameterizedGetRequestUrl<string>(
        Urls.handicapGHINRequestUrl,
        'handicapId',
        idsArray
    );
    try {
        const response = await axios.get(url, defaultAxiosRequestConfig);
        dbgLog(`requestGhinGolferData response: ${JSON.stringify(response)}`);
        if (response.status === 200 && response.data.golfersInfo) {
            const ghinsArray: GHINGolfer[] = [];
            const golfersInfo = response.data.golfersInfo;
            for (const handicapId of idsArray) {
                const ghin = golfersInfo[handicapId];
                let ghinGolfer: GHINGolfer = {
                    id: handicapId,
                    lastCheck: new Date().getTime(),
                    lastUpdate: new Date().getTime(),
                    status: ghin ? 'Active' : 'NotFound'
                }
                if (ghin) {
                    ghinGolfer.handicapIndex = ghin.handicapIndex;
                    if (ghin.homeCity) {
                        ghinGolfer.homeCity = ghin.homeCity;
                    }
                }
                ghinsArray.push(ghinGolfer);
            }
            dbgLog(`Return ${JSON.stringify(ghinsArray)}`);
            return Promise.resolve(ghinsArray);
        } else {
            dbgLog(`Reject ${JSON.stringify(response)}`);
            return Promise.reject(response)
        }
    } catch (err) {
        dbgLog(`Reject ${err}`);
        return Promise.reject(err);
    }
}

export async function findAndUpdateGhinGolfers(handicapIds: Set<string>) {
    const idsArray = Array.from(handicapIds);
    const requestIds: Set<string> = new Set(handicapIds);
    const ghinsStored: Map<string, GHINGolfer> = new Map<string, GHINGolfer>();
    const ghinsToReturn: Map<string, GHINGolfer> = new Map<string, GHINGolfer>();
    const toStore: GHINGolfer[] = [];
    dbgLog(`findAndUpdateGhinGolfers ${JSON.stringify(handicapIds)}`);
    if (handicapIds.size === 0) {
        return Promise.resolve(ghinsToReturn)
    }
    try {
        const ghinGolfers: GHINGolfer[] = [];
        for (let i = 0; i <=  Math.round(idsArray.length / 30); i++) {
            const nextIds = idsArray.slice(i * 30, Math.min(idsArray.length, (i + 1)));
            if (nextIds.length > 0) {
                ghinGolfers.push(...await Backend.getEntities<GHINGolfer>(Backend.ghinsQuery(nextIds)));
            }
        }
        dbgLog(`ghinGolfers in ghinGolfersDb ${JSON.stringify(ghinGolfers)}`);
        for (const ghinGolfer of ghinGolfers) {
            if (ghinGolfer && ghinGolfer.lastCheck > new Date().getTime() - DAY_MILLIS) { // ghin data exists in our cache and not expired.
                requestIds.delete(ghinGolfer.id)
                ghinsStored.set(ghinGolfer.id, ghinGolfer);
                ghinsToReturn.set(ghinGolfer.id, ghinGolfer);
            }
        }
        dbgLog(`GHIN requestIds ${JSON.stringify(requestIds)}`);
        const fetchedGhins = (requestIds.size > 0) ? await requestGhinGolferData(requestIds) : [];
        dbgLog(`fetchedGhins ${JSON.stringify(fetchedGhins)}`);
        for (const fetchedGhin of fetchedGhins) {
            if (ghinsStored.has(fetchedGhin.id)
                && fetchedGhin.handicapIndex === ghinsStored.get(fetchedGhin.id)?.handicapIndex
                && fetchedGhin.status === ghinsStored.get(fetchedGhin.id)?.status
            ) {
                fetchedGhin.lastUpdate = ghinsStored.get(fetchedGhin.id)!.lastUpdate;
            }
            toStore.push(fetchedGhin);
            ghinsToReturn.set(fetchedGhin.id, fetchedGhin);
        }
        if (toStore.length > 0) {
            dbgLog(`toStore ${JSON.stringify(toStore)}`);
            await Backend.updateOrAddBatch(Backend.ghinGolfersDb, toStore, true);
        }
        dbgLog(`ghinsToReturn ${JSON.stringify(ghinsToReturn)}`);
        return Promise.resolve(ghinsToReturn);
    } catch (err) {
        dbgLog(`reject ${err}`);
        return Promise.reject(err);
    }
}

import * as React from 'react';
import axios from 'axios';
import { Dialog, DialogActions, DialogContent, Divider, TextField, Slide } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import SendIcon from '@mui/icons-material/Send';
import * as Backend from '../../../util/firebase';
import { firebaseAuth } from '../../../util/firebase';
import { Email } from '../../Event';
import { fullName } from '../../../contact/Contact';
import ButtonBar from '../../../common/components/ButtonBar';
import AppButton from '../../../common/components/AppButton';
import LabeledField from '../../../common/form/LabeledFieldSimple';
import { Urls } from '../../../util/config';
import RichTextQuill from '../../../common/form/richtext/RichTextQuill';
import { showProgress } from '../../../redux/ReduxConfig';
import { FirebaseUserDataComponent } from '../../../common/WithData';
import { styles } from '../../../styles';
import { ContactDetails } from 'src/types/EventTypes';

export function sendMail(newMail: Email) {
    if (!Backend.firebaseAuth.currentUser) {
        return Promise.reject();
    }
    return Backend.firebaseAuth.currentUser.getIdToken(false).then(token => {
        const user = firebaseAuth.currentUser;
        const fromEmail = newMail.replyTo || user?.email || '';
        const msg = {
            token,
            individual: true,
            mailData: {
                to: newMail.recipients.filter(c => c.email).map(c => {
                    return { email: c.email!, name: fullName(c as ContactDetails) };
                }),
                from: { name: '', email: fromEmail },
                replyTo: fromEmail,
                subject: newMail.subject,
                text: newMail.text
            }
        };
        return axios.post(Urls.sendMail, msg);
    });
}

interface EmailProps {
    to: string;
    subject: string;
    text: string;
    open: boolean;
    handleCancel: () => void;
    handleSent: () => void;
}

interface State {
    newMail: Email;
}

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

type Props = EmailProps & WithStyles<typeof styles>;

class EmailDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            newMail: {
                subject: props.subject,
                replyTo: '',
                recipients: [{ id: '', lastName: props.to, gender: 'male', email: props.to, hidden: false }],
                text: props.text
            },
        };
    }

    private handleClose = () => this.props.handleCancel();

    private handlePropChange = (prop: keyof Email, value: any) => {
        const newMail = this.state.newMail;
        newMail[prop] = value;
        this.setState({ newMail });
    }

    private toList() {
        const { classes } = this.props;
        return (
            <div className={classes.chipContainer + ' ' + classes.scrollPanel}>
                {this.toListChips()}
            </div>
        );
    }

    private toListChips() {
        return this.state.newMail.recipients.map((recipient, idx) => <span key={idx}>{fullName(recipient as ContactDetails)}</span>);
    }

    private subject() {
        return (
            <TextField
                variant="standard"
                fullWidth
                value={this.state.newMail.subject}
                onChange={e => this.handlePropChange('subject', e.target.value)} />
        );
    }

    private replyTo() {
        const user = firebaseAuth.currentUser;
        return (
            <TextField
                variant="standard"
                type="email"
                value={this.state.newMail.replyTo || user?.email || ''}
                fullWidth
                onChange={e => this.handlePropChange('replyTo', e.target.value)} />
        );
    }

    private content() {
        return (
            <RichTextQuill value={this.state.newMail.text} onChange={val => this.handlePropChange('text', val)} />
        );
    }

    private handleSendEmail = () => {
        if (Backend.firebaseAuth.currentUser) {
            const hideProgress = showProgress('EmailDialog handleSendEmail');
            sendMail(this.state.newMail)
                .then(() => {
                    hideProgress('E-mail has been sent');
                    this.props.handleSent();
                })
                .catch(err => hideProgress('Failed to send e-mail: ' + err.message));
        }
    }

    private emailRemoteChanged = (newEmail: string) => {
        const newMail = this.state.newMail;
        newMail.replyTo = newEmail;
        this.setState({ newMail });
    }

    render() {
        const { newMail } = this.state;
        const { classes } = this.props;
        const emailText = newMail.text.replace(/<p><br><\/p>/g, '').trim();
        return (
            <Dialog
                fullScreen
                className={classes.emailDialog}
                open={this.props.open}
                onClose={this.handleClose}
                TransitionComponent={Transition}>
                <DialogContent>
                    <LabeledField label="To:" value={this.toList()} />
                    <Divider />
                    <LabeledField label="Reply-To:" value={this.replyTo()} />
                    <Divider />
                    <LabeledField label="Subject:" value={this.subject()} />
                    <Divider />
                    <LabeledField label="Content:" value={this.content()} />
                </DialogContent>
                <DialogActions>
                    <ButtonBar>
                        <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                        <AppButton color="secondary" onClick={this.handleSendEmail}
                            disabled={newMail.recipients.length === 0 || !newMail.subject.trim() || !emailText}><SendIcon className={classes.leftButtonIcon} /> Send</AppButton>
                    </ButtonBar>
                </DialogActions>
                <FirebaseUserDataComponent onData={data => this.emailRemoteChanged(data.email)} />
            </Dialog>
        );
    }
}

export default withStyles(styles)(EmailDialog);

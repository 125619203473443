import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { emailFormat, isCurrNumber, nameFormat, startsWithLetter } from '../util/utility';
import { CSSProperties } from "react";

export interface ValidationRule<T> {
    valid: (value?: T) => boolean;
    text: string;
}

export interface ValidatedTextFieldProps {
    keepError?: boolean;
    className?: string;
    textFieldProps: TextFieldProps;
    rules?: ValidationRule<string>[];
    register?: (name: string, field: ValidatedTextField | null) => void;
    validated?: () => void;
    style?: CSSProperties;
    inputValueModificationFunc?: (val: string) => string;
}

interface State {
    error: boolean;
    errorText?: string;
}

class ValidatedTextField extends React.Component<ValidatedTextFieldProps, State> {
    state: State = { error: false };

    private focused: number = 0;
    private textField: HTMLElement | null = null;

    componentDidMount() {
        if (this.props.register && this.props.textFieldProps.id) {
            this.props.register(this.props.textFieldProps.id, this);
        }
    }

    componentWillUnmount() {
        if (this.props.register && this.props.textFieldProps.id) {
            this.props.register(this.props.textFieldProps.id, null);
        }
    }

    focus(focused?: number) {
        if (this.textField && (!focused || this.focused < focused)) {
            this.focused++;
            this.textField.focus();
        }
    }

    validate(val?: any) {
        this.clearError();
        return this.validateImpl(val, undefined);
    }

    errorHighlighted() {
        const { error } = this.state;
        return error;
    }

    private validateImpl = (val?: any, onValidate?: (name: string, error: boolean) => void) => {
        const { rules } = this.props;
        if (!rules) {
            return true;
        }
        const value = val || val === 0 ? val : this.props.textFieldProps.value as string;
        for (let i = 0; i < rules.length; ++i) {
            if (!rules[i].valid(value)) {
                if (onValidate) {
                    this.setState({ error: true, errorText: rules[i].text }, () => onValidate(this.props.textFieldProps.id!, false));
                } else {
                    this.setState({ error: true, errorText: rules[i].text });
                }
                return false;
            }
        }
        if (onValidate) {
            this.setState({ error: false, errorText: '' }, () => onValidate(this.props.textFieldProps.id!, false));
        } else {
            this.setState({ error: false, errorText: '' });
        }
        return true;
    }

    valid() {
        const { rules } = this.props;
        if (!rules) {
            return true;
        }
        const value = this.props.textFieldProps.value as string;
        for (let i = 0; i < rules.length; ++i) {
            if (!rules[i].valid(value)) {
                return false;
            }
        }
        return true;
    }

    handleBlur = () => {
        this.validateImpl(undefined, this.props.validated);
    }

    handleFocus = () => {
        if (!this.props.keepError) {
            this.clearError();
        }
    }

    private applyInputValueModification = () => {
        const { inputValueModificationFunc, textFieldProps } = this.props;
        if (!!inputValueModificationFunc && typeof textFieldProps.value === 'string') {
            textFieldProps.InputProps = {
                ...textFieldProps.InputProps,
                value: inputValueModificationFunc(textFieldProps.value)
            };
        }
    };

    render() {
        const { textFieldProps, className } = this.props;
        const { errorText, error } = this.state;
        this.applyInputValueModification();
        return (
            <TextField
                fullWidth
                margin="dense"
                variant="standard"
                disabled={textFieldProps.disabled}
                name={textFieldProps.id}
                inputRef={input => this.textField = input}
                error={!textFieldProps.disabled && error}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                helperText={errorText}
                className={className}
                {...textFieldProps}
            />
        );
    }

    private clearError() {
        this.setState({ error: false, errorText: '' });
    }
}

export default ValidatedTextField;

export const required: ValidationRule<string> = {
    valid: (value?: string) => !!value && value.trim().length > 0,
    text: 'Required field'
};

export const emailValidation: ValidationRule<string> = {
    valid: (value?: string) => !value || emailFormat.test(value),
    text: 'Invalid email'
};

export const name: ValidationRule<string> = {
    valid: (value?: string) => !value || nameFormat.test(value),
    text: 'Invalid name'
};

export const homeCourseOrCity: ValidationRule<string> = {
    valid: (value?: string) => !value || startsWithLetter.test(value),
    text: 'Expected letter at the 1st position'
};

const regIsPosNumber = /^\d*\.?\d*$/;

export const posNumber: ValidationRule<string> = {
    valid: (value?: string) => !!value && value.trim().length > 0 && regIsPosNumber.test(value) && !isNaN(parseFloat(value)) && parseFloat(value) > 0,
    text: `Expected valid positive number`
};

export const validPriceAmount: ValidationRule<string> = {
    valid: (value?: string) => {
        return !value || (!!value?.length && isCurrNumber.test(value) && parseFloat(value) > 0);
    },
    text: 'Expected positive number having not more than 2 digits after the decimal point'
};

function isBetween(value: string, min: number, max: number) {
    const n = parseFloat(value);
    if (!isNaN(n)) {
        return n >= min && n <= max;
    } else {
        return false;
    }
}

export function limitedPosNumber(min: number, max: number) {
    return {
        valid: (value?: string) => !!value && value.trim().length > 0 && regIsPosNumber.test(value) && isBetween(value, min, max),
        text: `Expected number between ${min} and ${max}`
    };
}

export function regexp(exp: RegExp, text: string) {
    return {
        valid: (value?: string) => !value || exp.test(value),
        text: text
    };
}

export function minLength(chars: number) {
    return {
        valid: (value?: string) => !!value && value.trim().length >= chars,
        text: `Must be at least ${chars} characters`
    };
}

export function maxLength(chars: number) {
    return {
        valid: (value?: string) => !value || value.trim().length <= chars,
        text: `Max length ${chars} characters`
    };
}
import * as React from 'react';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Logo from '../common/Logo';
import { styles } from '../styles';
import { pushUrl } from '../redux/ReduxConfig';
import { Container, Item, ItemS } from '../common/Misc';
import Button from '../common/components/AppButton';
import * as Backend from '../util/firebase';
import { withProgress } from '../util/ProgressPromise';
import { processEnterKey } from '../util/react_utils';
import LoginPromptDialog from './LoginPromptDialog';

interface LoginState {
    eventId: string;
    getStarted: boolean;
}

class Login extends React.Component<WithStyles<typeof styles>, LoginState> {
    state: LoginState = {
        eventId: '',
        getStarted: false
    };

    componentDidMount() {
        Backend.trackEvent('view_landing');
    }

    private handleGetStarted = () => {
        Backend.trackEvent('user_get_started');
        this.setState({ getStarted: true });
    }

    private findEvent = () => {
        const eventId = this.state.eventId.trim().toUpperCase();
        Backend.trackEvent('user_lookups_event_id');
        withProgress(Backend.checkPublicEventId(eventId).then(() => pushUrl(`/event/${eventId}`)), true);
    }

    private eventPage = () => {
        const { classes } = this.props;
        const { eventId } = this.state;
        return (
            <div className={classes.padding5u} style={{ textAlign: 'center' }}>
                <Typography gutterBottom variant="h6">Have you been invited to join or follow an event?</Typography>
                <Typography gutterBottom variant="body2">Please use your event ID to find tournament information, schedule, standings and registration</Typography>
                <Container gutterBottom style={{ textAlign: 'center', display: 'inline-block' }}>
                    <ItemS xs={12} placeCenter placeBottom height={46}>
                        <TextField
                            variant="standard"
                            value={eventId}
                            style={{ width: 122 }}
                            id="outlined-search"
                            label="Event ID"
                            margin="dense"
                            onKeyDown={ev => processEnterKey(ev, this.findEvent)}
                            onChange={e => this.setState({ eventId: e.target.value.toUpperCase() })} />
                        &nbsp;&nbsp;&nbsp;
                        <Button className={classes.buttonCentered} onClick={this.findEvent} disabled={!eventId.trim()}>FIND</Button>
                    </ItemS>
                </Container>
            </div>
        );
    }

    private tournamentPage = () => {
        const { classes } = this.props;
        return (
            <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
                <Typography gutterBottom variant="h5">Get started for free today!</Typography>
                <Button className={classes.buttonCentered} color="secondary" onClick={this.handleGetStarted}>GET STARTED</Button>
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        const { getStarted } = this.state;
        return <>
            {getStarted && <LoginPromptDialog open={true} onClose={() => { this.setState({ getStarted: false }); }} />}
            <Container>
                <Item xs={12} placeCenter>&nbsp;</Item>
                <Item xs={12} placeCenter><Logo fontSize="2.2em" /></Item>
                <Item xs={12} placeCenter>Free, easy golf tournament software</Item>
                <div className={classes.loginPaper}>
                    <Typography variant="body1">Golf Pad Events is the free golf tournament management software. It's aimed to help anyone who organizes golf tournaments, from small groups of friends to professional directors and golf clubs.<br />
                        Ease of use is our number one goal, both for the organizers and golfers. Whether you are using Excel spreadsheets to run your tournament and look for more convenience, or pay hundreds of dollars for a dedicated system and would rather keep the cash, Golf Pad Events can be a great fit!<br /></Typography>
                    <Typography variant="body1">Below are just a few of many features Golf Pad Events currently offers.</Typography>
                    <ul style={{ fontSize: '0.875rem' }}>
                        <li>Individual and team formats: Strokeplay, Stableford, Best ball, Scramble, Chapman and Alternate shot.</li>
                        <li>Gross and net scoring with automatic playing handicap calculation.</li>
                        <li>Side games: Longest drive, KP (closest to pin) and skins.</li>
                        <li>Regular and shotgun schedules.</li>
                        <li>Import from Excel and CSV tables.</li>
                        <li>Fully customizeable public event site with description schedule and standings.</li>
                    </ul>
                    <Typography variant="body1">Please note the Golf pad Events website is currently in beta and we are constantly adding new features and tools.</Typography>
                    <Typography variant="body1">Do you have ideas? Do you know what you'd want to see in a golf tournament software? Why not get started today and make your voice heard.</Typography>
                </div>
                <ItemS xs={12} placeCenter neg><this.tournamentPage /></ItemS>
                <ItemS xs={12} placeCenter neg><this.eventPage /></ItemS>
            </Container>
        </>;
    }
}

export default withStyles(styles)(Login);

import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Tabs, Tab, Badge, Box, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { appTabTheme, useAppStyles } from '../../../styles';
import { EventData } from '../../../types/EventTypes';
import { pushUrl } from '../../../redux/ReduxConfig';
import GolfersList from './list/GolfersList';
import InvitesList from './list/InvitesList';
import TeamsList from './list/TeamsList';
import ScheduleList from './pairings/ScheduleList';
import { GolferStats } from 'src/event/list/EventInfo';
import { tabName } from '../common/LinkTabs';

type SelectedTab = 'list' | 'teams' | 'schedule' | 'invites';

interface Props {
    eventData: EventData;
    initial?: string;
    golferStats: GolferStats;
    onSelectedTab: (value: string) => void;
}

function GolfersTab(props: Props) {
    const { initial, eventData, golferStats } = props;
    const { event } = eventData;
    const classes = useAppStyles();
    const golfersTabLabel = <>
        <Box>
            <Badge color="error" overlap="rectangular" variant="dot" invisible={golferStats.golfersAvailable}><Typography variant="caption">LIST &nbsp;</Typography></Badge>
            <Typography variant="subtitle1">{golferStats.golfersSubLabel}</Typography>
        </Box>
    </>;
    const teamsTabLabel = <>
        <Box>
            <Badge color="error" overlap="rectangular" variant="dot" invisible={golferStats.allTeamsPaired}><Typography variant="caption">TEAMS &nbsp;</Typography></Badge>
            <Typography variant="subtitle1">{golferStats.teamsSubLabel}</Typography>
        </Box>
    </>;
    const groupsTabLabel = <>
        <Box>
            <Badge color="error" overlap="rectangular" variant="dot" invisible={golferStats.allScheduled && golferStats.groupsCount > 0}><Typography variant="caption">SCHEDULE &nbsp;</Typography></Badge>
            <Typography variant="subtitle1">{golferStats.groupsSubLabel}</Typography>
        </Box>
    </>;
    const invitesTabLabel = <>
        <Box>
            <Typography variant="caption">INVITES</Typography>
            <Typography variant="subtitle1">{golferStats.invitesSubLabel}</Typography>
        </Box>
    </>;
    const parentUrl = `/events/${event.id}/golfers`;
    // const pathName = location.pathname.replace(parentUrl, '').replace('/', '');
    // const tab = pathName || 'list';
    const tab = tabName(parentUrl, 'list');
    const changeTab = (_: React.ChangeEvent<{}>, value: SelectedTab) => {
        props.onSelectedTab(value);
        pushUrl(`${parentUrl}/${value}`);
    }
    return <>
        <Box className={classes.stickyTop}>
            <ThemeProvider theme={appTabTheme}>
                <Tabs
                    value={tab}
                    onChange={changeTab}
                    textColor="secondary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label={golfersTabLabel} value="list" />
                    <Tab label={teamsTabLabel} value="teams" />
                    <Tab label={groupsTabLabel} value="schedule" />
                    <Tab label={invitesTabLabel} value="invites" />
                </Tabs>
            </ThemeProvider>
            <Routes>
                <Route path="list" element={<GolfersList eventData={eventData} />} />
                <Route path="schedule" element={<ScheduleList eventData={eventData} />} />
                <Route path="teams" element={<TeamsList eventData={eventData} />} />
                <Route path="invites" element={<InvitesList eventData={eventData} />} />
                <Route path="/*" element={<Navigate to={initial ?? 'list'} replace />} />
            </Routes>
        </Box>
    </>
}

export default GolfersTab;

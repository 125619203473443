import * as React from 'react';

const PD = (dateField?: string) => `FORMAT_DATETIME('%Y %h %e, %R', PARSE_DATETIME('%s', CAST(TRUNC(${dateField}/1000) AS STRING)))`;

export type QueryElem = {
    name: string;
    dates?: Array<string>;
    dateField?: string;
    expandable?: boolean;
    description?: React.ReactNode;
    aliases?: Map<string, string>;
    query?: (dataset: string) => string;
};

export type QueryParam = {
    name: string;
    value: any;
};

const EVENTS_DATES = ['date', 'lastModified'];

export const BQ_QUERIES: Array<QueryElem> = [{
    name: 'Events basic info',
    description: 'Events list with basic information',
    expandable: true,
    dates: EVENTS_DATES,
    aliases: new Map([
        ['eventId', 'events.__key__.name']
    ]),
    query: (dataset: string) => `
SELECT
  events.__key__.name AS eventId,
  publicId,
  name AS eventName,
  userId,
  userName,
  userEmail,
  course.name AS courseName,
  handicapSystem,
  teeTime.mode AS mode,
  ${PD('date')} AS eventDate,
  ${PD('lastModified')} AS lastModifiedDate
FROM
  ${dataset}.events
WHERE
  leaderboard IS FALSE WHERE_OTHER
ORDER BY
  date DESC`
}, {
    name: 'Events & Admins',
    description: 'Events list with admin names',
    dates: EVENTS_DATES,
    aliases: new Map([
        ['eventId', 'events.__key__.name']
    ]),
    query: (dataset: string) => `
SELECT
  __key__.name AS eventId,
  ${PD('date')} AS eventDate,
  userId,
  userName,
  userEmail
FROM
  ${dataset}.events
WHERE
  leaderboard IS FALSE WHERE_OTHER
ORDER BY
  date DESC`
}, {
    name: 'All admins emails',
    description: 'List all admin emails',
    dates: EVENTS_DATES,
    aliases: new Map([
        ['eventId', 'events.__key__.name']
    ]),
    query: (dataset: string) => `
  SELECT DISTINCT
    userEmail
  FROM
    ${dataset}.events
  WHERE
    userEmail IS NOT NULL AND userEmail != '' WHERE_OTHER`
}, {
    name: 'Events & Reported Scores',
    description: 'Events list with corresponding calculated scores (stroke sums)',
    expandable: true,
    dates: EVENTS_DATES,
    aliases: new Map([
        ['eventId', 'events.__key__.name']
    ]),
    query: (dataset: string) => `
WITH
  reported_scores AS (
    WITH
      reported_scores_i AS (
      SELECT
        __key__.path AS reported_scores_path,
        TRIM(REPLACE(SPLIT(__key__.path)[1], '"', '')) as reported_scores_event_id,
        TRIM(REPLACE(SPLIT(__key__.path)[3], '"', '')) as reported_scores_id,
        ustroke,
      FROM ${dataset}.\`reported-scores\`, UNNEST(strokes) AS ustroke )
    SELECT reported_scores_event_id, reported_scores_id, SUM(ustroke) AS reportedScore
    FROM reported_scores_i
    GROUP BY reported_scores_event_id, reported_scores_id ),
  admin_scores AS (
    WITH
      admin_scores_i AS (
      SELECT
        __key__.path AS admin_score_path,
        TRIM(REPLACE(SPLIT(__key__.path)[1], '"', '')) as admin_scores_event_id,
        TRIM(REPLACE(SPLIT(__key__.path)[3], '"', '')) as admin_scores_id,
        gross,
        ugross,
      FROM ${dataset}.scores, UNNEST(gross) AS ugross )
    SELECT admin_scores_event_id, admin_scores_id, SUM(ugross) AS adminScore
    FROM admin_scores_i
    GROUP BY admin_scores_event_id, admin_scores_id )
SELECT
  events.__key__.name AS eventId,
  userId,
  userName,
  PARSE_DATETIME('%s', CAST(TRUNC(date/1000) AS STRING)) AS eventDate,
  handicapSystem,
  course.name AS courseName,
  handicapSystem,
  teeTime.mode AS mode,
  reported_scores_id as golferId,
  reportedScore,
  adminScore
FROM ${dataset}.events AS events
LEFT JOIN reported_scores ON reported_scores_event_id = events.__key__.name
LEFT JOIN admin_scores ON admin_scores_event_id = events.__key__.name AND reported_scores_id = admin_scores_id
WHERE 
  leaderboard=FALSE WHERE_OTHER
ORDER BY
  date DESC, eventId, reported_scores_id, admin_scores_id`
}, {
    name: 'Events & Raw Scores',
    description: 'Events list with non-empty score values',
    dates: EVENTS_DATES,
    query: (dataset: string) => `
WITH
  event_scores AS (
  SELECT
    events.__key__.name AS eventId,
    userId,
    userName,
    date,
    FORMAT_DATETIME('%Y %h %e, %R', PARSE_DATETIME('%s', CAST(TRUNC(date/1000) AS STRING))) AS eventDate,
    course.name AS courseName,
    handicapSystem,
    teeTime.mode AS mode,
    --gross[0] AS firstGross,
    TRIM(REPLACE(SPLIT(scores.__key__.path)[1], '"', '')) AS evntId,
    TRIM(REPLACE(SPLIT(scores.__key__.path)[3], '"', '')) AS golferId,
    gross,
    deleted
  FROM
    ${dataset}.events AS events
  LEFT JOIN
    ${dataset}.scores AS scores
  ON
    INSTR(scores.__key__.path, events.__key__.name) > 0 )
SELECT
  eventId, userName, userId, eventDate, handicapSystem, mode, golferId, gross
FROM
  event_scores
WHERE
  gross IS NOT NULL WHERE_OTHER
ORDER BY
  date DESC, eventId, golferId`
}, {
    name: 'Events per User',
    description: 'Selects # of events per user',
    dates: EVENTS_DATES,
    query: (dataset: string) => `
SELECT
  userId,
  userName,
  userEmail,
  COUNT(*) AS num,
  PARSE_DATETIME('%s', CAST(TRUNC(MIN(date)/1000) AS STRING)) AS dateMin,
  PARSE_DATETIME('%s', CAST(TRUNC(MAX(date)/1000) AS STRING)) AS dateMax
FROM
  ${dataset}.events AS events
WHERE
  leaderboard=FALSE WHERE_OTHER
GROUP BY
  userId,
  userName,
  userEmail
ORDER BY
  num DESC`
}, {
    name: 'Events per Date',
    dates: EVENTS_DATES,
    description: 'Selects # of events per day',
    query: (dataset: string) => `
SELECT
  FORMAT_DATE('%e %b %Y', PARSE_DATETIME('%s', CAST(TRUNC(date/1000) AS STRING))) AS day,
  count(*) as num
FROM 
  ${dataset}.events AS events
WHERE
  leaderboard=FALSE WHERE_OTHER
GROUP BY day
ORDER BY PARSE_DATE('%e %b %Y', day) DESC`
}, {
    name: 'Events per Month',
    dates: EVENTS_DATES,
    description: 'Selects # of events per month',
    query: (dataset: string) => `
SELECT
  FORMAT_DATE('%b %Y', PARSE_DATETIME('%s', CAST(TRUNC(date/1000) AS STRING))) AS month,
  count(*) as num
FROM 
  ${dataset}.events AS events
WHERE
  leaderboard=FALSE WHERE_OTHER
GROUP BY month
ORDER BY PARSE_DATE('%b %Y', month) DESC`
}, {
    name: 'Events per Month & User',
    dates: EVENTS_DATES,
    description: 'Selects # of events per month and per user',
    query: (dataset: string) => `
SELECT
  FORMAT_DATE('%b %Y', PARSE_DATETIME('%s', CAST(TRUNC(date/1000) AS STRING))) AS month,
  userId,
  userName,
  userEmail,
  COUNT(*) AS num
FROM
  ${dataset}.events AS events
WHERE
  leaderboard=FALSE WHERE_OTHER
GROUP BY
  month,
  userId,
  userName,
  userEmail
ORDER BY
  PARSE_DATE('%b %Y', month) DESC`
}, {
    name: 'Events & Golfers',
    description: 'Event list with participant information',
    dates: EVENTS_DATES,
    aliases: new Map([
        ['eventId', 'events.__key__.name']
    ]),
    query: (dataset: string) => `
WITH
  contacts AS (
  SELECT
    --__key__.path AS contact_path,
    TRIM(REPLACE(SPLIT(__key__.path)[1], '"', '')) AS golferEventId,
    TRIM(REPLACE(SPLIT(__key__.path)[3], '"', '')) AS golferId,
    email,
    firstName,
    lastName,
    gender,
    handicapId,
    handicapIndex.provided AS hcpType,
    playingHandicap.provided AS playingHcpType,
    CASE WHEN handicapIndex.float IS NOT NULL THEN handicapIndex.float ELSE handicapIndex.integer END handicapIndex,
    CASE WHEN playingHandicap.float IS NOT NULL THEN playingHandicap.float ELSE playingHandicap.integer END playingHandicap,
    disqualified,
    withdrawn,
    homeCourseOrCity
  FROM
    ${dataset}.contacts
  WHERE
    hidden=FALSE )
SELECT
  events.__key__.name AS eventId,
  events.name AS eventNname,
  golferId,
  email,
  firstName,
  lastName,
  gender,
  handicapId,
  --hcpType,
  handicapIndex,
  --playingHcpType,
  playingHandicap,
  CASE WHEN disqualified IS TRUE THEN 'TRUE' ELSE 'FALSE' END disqualified,
  CASE WHEN withdrawn IS TRUE THEN 'TRUE' ELSE 'FALSE' END withdrawn,
  homeCourseOrCity
FROM
  ${dataset}.events AS events
LEFT JOIN
  contacts
ON
  --INSTR(contact_path, events.__key__.name) > 0
  golferEventId = events.__key__.name
WHERE
  leaderboard=FALSE WHERE_OTHER
ORDER BY
  date DESC,
  eventId,
  golferId`
}, {
    name: 'Events & Participants Count',
    description: 'Event list with participant count info',
    dates: EVENTS_DATES,
    aliases: new Map([
        ['eventId', 'events.__key__.name']
    ]),
    query: (dataset: string) => `
SELECT
  events.__key__.name AS eventId,
  name AS eventName,
  userId,
  userName,
  userEmail,
  PARSE_DATETIME('%s', CAST(TRUNC(events.date/1000) AS STRING)) AS eventDate,
  --ARRAY_AGG(contacts.__key__.path) AS contactIds,
  ARRAY_LENGTH(ARRAY_AGG(contacts.__key__.path)) AS participantsCount
FROM
  ${dataset}.events AS events
LEFT JOIN
  ${dataset}.contacts AS contacts
ON
  INSTR(contacts.__key__.path, events.__key__.name) > 0
WHERE
  contacts.__key__.path IS NOT NULL
  AND contacts.hidden=FALSE
  AND leaderboard=FALSE WHERE_OTHER
GROUP BY
  events.__key__.name,
  events.name,
  userId,
  userName,
  userEmail,
  date
ORDER BY
  date DESC,
  eventId`
}, {
    name: 'Roster',
    description: 'User roster - the historic list of all user golfers',
    query: (dataset: string) => `
SELECT
  TRIM(REPLACE(SPLIT(__key__.path)[1], '"', '')) AS userId,
  __key__.name as golferId,
  email,
  firstName,
  lastName,
  gender,
  handicapId,
  CASE WHEN handicapIndex.float IS NOT NULL THEN handicapIndex.float ELSE handicapIndex.integer END handicapIndex,
FROM
  ${dataset}.roster
WHERE
  TRUE WHERE_OTHER
ORDER BY
  userId, golferId`
}, {
    name: 'All record counts',
    description: 'Event list with participant count info',
    query: (dataset: string) => `
WITH
  counts AS (
  SELECT
    'competitions' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.competitions
  UNION ALL
  SELECT
    'contacts' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.contacts
  UNION ALL
  SELECT
    'events' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.events
  UNION ALL
  SELECT
    'groups' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.groups
  UNION ALL
  SELECT
    'participants' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.participants
  UNION ALL
  SELECT
    'reported-scores' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.\`reported-scores\`
  UNION ALL
  SELECT
    'scores' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.scores
  UNION ALL
  SELECT
    'team-scores' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.\`team-scores\`
  UNION ALL
  SELECT
    'teams' AS name,
    COUNT(*) AS num
  FROM
    ${dataset}.teams )
SELECT
  *
FROM
  counts
UNION ALL
SELECT
  'all' AS name,
  SUM(num) AS num
FROM
  counts`
}, {
    name: '', // separator
}, {
    name: 'events',
    dates: EVENTS_DATES,
    dateField: 'date'
}, {
    name: 'scores',
    dates: ['updateTime'],
}, {
    name: 'competitions'
}, {
    name: 'contacts'
}, {
    name: 'groups'
}, {
    name: 'participants'
}, {
    name: 'reported-scores'
}, {
    name: 'team-scores'
}, {
    name: 'teams'
}, {
    name: '', // separator
}, {
    name: 'custom',
    description: 'Provide custom query below'
}];

export function getQuery(queryDataset: string, querySelection: string, queryLimit: number, queryDateField?: string, from?: string, to?: string, selectedFields?: Array<QueryParam>, deleted?: boolean) {
    const q = BQ_QUERIES.find(q => q.name === querySelection);
    const dates = q?.dates;
    const expandable = q?.expandable ?? !q?.query;
    const selectArgs = q?.dateField ? `${PD(q.dateField)} as dateFormatted, *` : '*';
    const description = q?.description ?? (q?.query ? '' : <span>Selects all fields from <b>{querySelection}</b> collection</span>);
    queryDataset = '`' + queryDataset + '`';
    const table = '`' + querySelection + '`';
    let condition = '';
    if (queryDateField) {
        if (from) {
            if (condition) {
                condition += 'AND ';
            }
            condition += `${queryDateField} >= ${new Date(from).getTime()} `;
        }
        if (to) {
            if (condition) {
                condition += 'AND ';
            }
            condition += `${queryDateField} <= ${new Date(to).getTime()} `;
        }
    }
    if (selectedFields) {
        selectedFields.forEach(selectedField => {
            if (condition) {
                condition += 'AND ';
            }
            const selectedFieldName = q?.aliases?.get(selectedField.name) ?? selectedField.name;
            if (selectedField.value === 'is null') {
                condition += `${selectedFieldName} IS NULL `;
            } else if (selectedField.value === 'is not null') {
                condition += `${selectedFieldName} IS NOT NULL `;
            } else {
                condition += `${selectedFieldName} = '${selectedField.value ?? ''}' `;
            }
        });
    }
    let query = (q?.query ? q.query(queryDataset) : `
SELECT
  ${selectArgs}
FROM
  ${queryDataset}.${table}`);
    if (query.indexOf(`${queryDataset}.events`) >= 0) {
        if (condition) {
            condition += 'AND ';
        }
        if (deleted) {
            condition += 'deleted is TRUE';
        } else {
            condition += 'deleted is NOT TRUE';
        }
    }
    if (condition) {
        if (query.indexOf('WHERE_OTHER') >= 0) {
            query = query.replace('WHERE_OTHER', ` AND ${condition}`);
        } else {
            query += `
WHERE
  ${condition}`;
        }
    }
    query = query.replace('WHERE_OTHER', '');
    query += `
LIMIT
  ${queryLimit}`;
    query = query.trim();
    return { query, description, dates, expandable };
}

import * as React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { drawerWidth1 } from '../styles';
import { GobletIcon, SettingsIcon } from 'src/common/Icons';
import AccountSettings from './AccountSettings';
import AccountPlans from './AccountPlans';
import { accountAction } from './StripeRedirect';
import { useUserAware } from './Auth';
import { AppDrawerItem } from 'src/common/components/AppDrawer';
import { TemplateDrawer } from 'src/event/list/EventDrawer';

export function AccountDrawer() {
    const tabs: Array<AppDrawerItem> = [
        {
            id: 'all',
            name: 'My events',
            url: '/events',
            icon: <GobletIcon />
        }, {
            id: 'account',
            name: 'Account',
            url: ''
        }, {
            id: 'settings',
            name: 'Settings',
            url: '/account/settings',
            icon: <SettingsIcon />,
        // }, {
        //    id: 'plans',
        //    name: 'Plans',
        //    url: '/account/plans',
        //    icon: <PlansIcon />,
        }
    ];
    return (
        <TemplateDrawer tabs={tabs} width={drawerWidth1} />
    );
}

function Account() {
    const userAware = useUserAware();
    return (
        <div style={{ display: 'flex' }}>
            <AccountDrawer />
            <Routes>
                <Route path="settings" element={<AccountSettings />} />
                <Route path="plans" element={<AccountPlans variant="long" action={actionName => accountAction(userAware, actionName)} />} />
                <Route path="*" element={<Navigate to={'settings'} replace />} />
            </Routes>
            <Outlet />
        </div>
    );
}

export default Account;
